import React, { lazy, useState, useEffect, useContext, useRef } from "react";
import { Header, SLButton } from "../../components/core/Controls";
import { SLNotification } from "../../components/core/utility";
import { FlexColumn, FlexRow, MediumText, SmallText, Spacer, UltraMediumText } from "../../components/core/Utility/index";
import { CustomDropdown } from "./CreateNewJob";
import { Modal, ModalFooter, ModalTitle } from "@vds/modals";
import styled from "styled-components";
import { Icon } from "@vds/icons";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar.css";
import moment from "moment";
import { Loader } from "@vds/loaders";
import { getSchedule } from "../../services/scheduler/getSchedule";
import { createSchedule } from "../../services/scheduler/createSchedule";
import { groupBy, maxBy, sortBy, sortedUniqBy } from "lodash";
import { generate } from "shortid";
import { Line } from "@vds/lines";
import { switchTab, getMapUrl } from "../C4S Decommission - Replacement/utils";
import { addPage } from "../../../utility/navigation";
import { Body, Micro } from "@vds/typography";
import { AccountDetailsContext } from "../../context/accountDetailsContext";
import { getReasonlist } from "../../services/scheduler/getReasonlist";
import { rescheduleJob } from "../../services/scheduler/rescheduleJob";
import { StyledModal } from "./RescheduleModal";
import { durationUnitCalculation, handleERPError, handleStartDate, sessionData } from "../../utility/sessionData";
import ConfirmationModal from "./ConfirmationModal";
import { updateNewrelicStats } from "../../../utility/newrelic";
import { TextLink } from "@vds/buttons";
import { SlotButtons } from "./SlotsButton";
import RescheduleReasonModal from "./RescheduleReasonModal";
import { getSlots } from "../../services/scheduler/getSlots";
import { cancelBooking } from "../../services/scheduler/cancelBooking";
import { AlternateManualEntryMetadata } from "./AlternateManualSchedule";
import { css } from "@emotion/react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import StartDateChangeModal from "./StartDateChangeModal";
import useOutsideClick from "../../customHook/use-outside-click";
import { getMileageList } from "../../services/scheduler/getMileageList";
import { handleSalesOrg } from "../../pages/OpenJobs/CreateAdditionalFees";
import { getDistanceUnitData } from "../../services/scheduler/getDistanceUnitData";
import { Input } from "@vds/inputs";

const customSpinner = css`
  background-color: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  // width: 100%;
  height: 100%;
  z-index: 99999;
  position: fixed;
  opacity: 0.8;
  & svg {
    width: 6%;
  }
  .CircularProgressbar-path {
    stroke: green;
  }
  .CircularProgressbar-text {
    fill: #000000;
    font-size: 25px;
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & div:nth-child(2) {
    margin-right: 10%;
    cursor: pointer;
  }
`;

const SlotsContainer = styled.div`
  width: 45rem;
  @media (max-width: 767px) {
    width: 40rem;
  }
`;

const Footer = styled.div`
  height: auto;
  bottom: 0px;
  position: fixed;
  padding-bottom: 0px;
  background-color: white;
  width: 100%;
  border-top: 1px solid black;
`;

export const CustomInput = styled(Input)`
  padding-bottom: 0.6rem;
  height: auto;
  width: ${(props) => (props.formWidth ? props.formWidth : "")};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
  & label {
    & p {
      font-size: 12px;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
    }
  }
  & input {
    font-size: 12px;
    height: ${(props) => (props.height ? props.height : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      border-top: 1px solid #dfe0e0;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
  & span {
    width: ${(props) => (props.width ? props.width : "")};
    & span {
      & span {
        & div:not(:first-child) {
          & svg {
            display: none; // hide success tick icon
          }
        }
      }
    }
  }
`;

export default function ScheduleJob({ jobData, expandJobVendorID, jobDetails, mapUrl }) {
  const { accountDetails, cspDetails, caseDetails, source } = useContext(AccountDetailsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [jobType] = useState(jobData.jobDetails[0].jobType);
  const [requestedDate, setRequestedDate] = useState(moment(jobData.startDate).format("MM/DD/YYYY"));
  const [apptLength] = useState(jobData.appointmentLength);
  const [vehicleCount] = useState(jobData.jobDetails[0].vehicleCount);
  const [vendorId, setVendorId] = useState(jobData.vendorId);
  const [erpVendorId, setErpVendorId] = useState(jobData.erpVendorId);
  const [jobId] = useState(jobData.id);
  const [csp] = useState(jobData.jobDetails[0].cspSfdcId);
  const [workTicketNumber] = useState(jobData.jobDetails[0].workTickets.map((workTickets) => workTickets.workTicket).join(","));
  const [serviceJobId] = useState(jobData.serviceJobId);
  const [address, setAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [spotenabledDates, setSpotEnabledDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date(jobData.startDate));
  const [availableDateInfo, setavailableDateInfo] = useState([]);
  const [totalMiles, settotalMiles] = useState([]);
  const [notification, setNotification] = useState();
  const [slotDetails, setSlotDetails] = useState({});
  const [selectedSlot, setSelectedSlot] = useState({});
  const [submitDisability, setSubmitDisability] = useState(true);
  const [availabilityDates, setAvailabilityDates] = useState([]);
  const [reschedule] = useState(jobData.reschedule);
  const [reason, setReason] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [rescheduleReasonList, setRescheduleReasonList] = useState([]);
  const [confirmModal, setConfirmModal] = useState(null);
  const [viewMore, setViewMore] = useState(false);
  const [viewCount, setViewCount] = useState(3);
  const [kbArticleLink] = useState("https://vzt-kb.vtitel.com/Reveal/Reveal_Support/Reveal_Scheduling/Scheduling_and_Shipping_Scenarios");
  const [timezone, setTimezone] = useState(jobData.timezone || "");
  const [rescheduleReasonModalStatus, setRescheduleReasonModalStatus] = useState({
    isModalOpen: false,
    slotTime: "",
    reasonList: [],
  });
  let footerRef = useRef(null);
  const [additionalSlotsBtn, setAdditionalSlotsBtn] = useState(false);
  const [timeSelected, setTimeSelected] = useState({});
  const [transactionId, setTransactionId] = useState();
  const [oldResponseLength, setOldResponseLength] = useState(0);
  const [sameResponse, setSameResponse] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [spinnerValue, setSpinnerValue] = useState(0);
  const [firstEnabledDate] = useState(handleStartDate()); // useState(moment().add(2, "days").format("MM/DD/YYYY"));
  const [lastEnabledDate, setlastEnabledDate] = useState(handleStartDate(false).add(59, "days").format("MM/DD/YYYY"));
  const [datesNeeded, setdatesNeeded] = useState();
  const [startDateChangeModal, setStartDateChangeModal] = useState(null);
  const [confirmedStartDate, setConfirmedStartDate] = useState(true);
  const [customerType, setCustomerType] = useState("");
  const [distanceUnit, setDistanceUnit] = useState("");
  const [distanceMMID, setDistanceMMID] = useState("");
  const [distanceDescription, setDistanceDescription] = useState("");
  const [distanceCalculationType, setDistanceCalculationType] = useState(
    source?.source == "csp"
      ? cspDetails?.cspDetails[0].distanceDescription === "Mileage - Roundtrip"
        ? "return"
        : "oneway"
      : caseDetails?.caseDetails[0].distanceDescription === "Mileage - Roundtrip"
      ? "return"
      : "oneway"
  );
  let spinnerPercentage;

  const salesOrgBasedCurrency = {
    SO_3001: "AUD",
    SO_6901: "NZD",
    SO_1001: "EUR",
    SO_1201: "EUR",
    SO_4203: "EUR",
    SO_4211: "EUR",
    SO_3701: "EUR",
    SO_1101: "GBP",
    SO_2901: "CAD",
    SO_9001: "USD",
    SO_5501: "MXN",
  };

  const [mileageType, setMileageType] = useState("");
  const [internationalFlag, setInternationalFlag] = useState(
    source?.source == "csp" ? cspDetails?.cspDetails[0].vpmInternational : caseDetails?.caseDetails[0].vpmInternational
  );
  const [selectedMileageType, setSelectedMileageType] = useState("");
  const [enableManualScheduleOption, setEnableManualScheduleOption] = useState(false);
  const [cspOrCaseId, setCspOrCaseId] = useState(source?.source == "csp" ? cspDetails?.cspDetails[0].CSPID : caseDetails?.caseDetails[0].CaseSFDCID);
  const [accountId, setAccountId] = useState(jobData?.accountId);
  const handleBannerClickOutside = () => {
    setNotification(null);
  };

  const clickRef = useOutsideClick(handleBannerClickOutside);
  const defaultCsp = cspDetails?.cspDetails?.filter((cspDetail) => cspDetail?.DefaultCSP === "1")[0] ?? null;

  async function fetchDistanceUnit() {
    let userID = sessionStorage.getItem("userId");
    const response = await getDistanceUnitData(accountId, cspOrCaseId, userID);
    if (response.data.statusCode == 200 && response.data.data) {
      let fetchedValue;
      if (source?.source == "csp") {
        fetchedValue = response.data.data.CSP[0];
      } else {
        fetchedValue = response.data.data.CASEDetails[0];
      }
      setInternationalFlag(fetchedValue?.vpmInternational);
      setDistanceDescription(fetchedValue?.distanceDescription);
      setDistanceMMID(fetchedValue?.distanceMMID);
      if (fetchedValue?.distanceDescription === "Mileage - Roundtrip") {
        setDistanceCalculationType("roundtrip");
      } else {
        setDistanceCalculationType("oneway");
      }
      if (fetchedValue?.distanceMeasure == "MI") {
        setDistanceUnit("Miles");
      } else {
        setDistanceUnit("Kilometers");
      }
    }
  }

  useEffect(() => {
    if (accountDetails.vztSegment.toLocaleLowerCase().includes("smb")) {
      setCustomerType("B");
    } else if (accountDetails.vztSegment.toLocaleLowerCase().includes("micro")) {
      setCustomerType("M");
    } else if (
      accountDetails.vztSegment.toLocaleLowerCase().includes("government") ||
      accountDetails.vztSegment.toLocaleLowerCase().includes("public sector")
    ) {
      setCustomerType("G");
    } else if (accountDetails.vztSegment.toLocaleLowerCase().includes("enterprise")) {
      setCustomerType("E");
    } else if (accountDetails.vztSegment.toLocaleLowerCase().includes("strategic")) {
      setCustomerType("S");
    }
    fetchDistanceUnit();
  }, []);

  useEffect(() => {
    if (confirmedStartDate) {
      getJobTransactionId();
    }
    return () => setConfirmedStartDate(false);
  }, [requestedDate, confirmedStartDate]);

  useEffect(() => {
    if (slotDetails?.slot) {
      document.getElementById("Scheduler").addEventListener("click", handleClickOutside, false);
    }
    return () => document.getElementById("Scheduler")?.removeEventListener("click", handleClickOutside, false);
  }, [slotDetails]);

  const handleClickOutside = (event) => {
    if (event.target.id !== "SlotButton" && !footerRef.current.contains(event.target)) {
      setSlotDetails({});
      setSubmitDisability(true);
    }
  };

  const notify = (content, type) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />);
  };

  const sortedMiles = (groupedByMiles) => {
    let sorted = [];
    Object.keys(groupedByMiles)
      .sort((a, b) => Number(a) - Number(b))
      .reduce((idx, mile) => {
        let oneMile = {};
        if (mile) {
          oneMile[mile] = groupedByMiles[mile];
          sorted.push(oneMile);
        }
      }, {});
    return sorted;
  };

  const handlePercentCalculation = (isMore = false) => {
    let seconds = 0;
    let totalSeconds = isMore ? 11 : 15;
    spinnerPercentage = setInterval(() => {
      seconds += 1;
      let percent = Math.round((seconds * 100) / totalSeconds);
      percent > 98 ? setSpinnerValue(99) : setSpinnerValue(percent);
    }, 1000);
  };

  const clearSpinner = () => {
    setSpinner(false);
    clearInterval(spinnerPercentage);
    setSpinnerValue(0);
  };

  const getJobTransactionId = async () => {
    updateNewrelicStats();
    let name, fName, lName;
    name = jobData.contactName.split(" ");
    fName = name.length > 1 ? name.slice(0, name.length - 1).join(" ") : name[0];
    lName = name.length > 1 ? name[name.length - 1] : "";
    setFirstName(fName);
    setLastName(lName);
    let address2 = jobData.address2 ? jobData.address2 + ", " : "";
    setAddress(jobData.address1 + ", " + address2 + jobData.city + ", " + jobData.stateCode + ", " + jobData.zipCode + ", " + jobData.country);
    let data = {
      Header: {
        requestControlId: "vzc_" + Date.now(),
      },
      Body: {
        header: {
          clientAppName: "VZC",
          clientAppUserName: "ECOM",
          internationalFlag: internationalFlag,
        },
        orderNum: jobData.id,
        vendorId: expandJobVendorID ? expandJobVendorID : reschedule ? vendorId : null,
        addressLine1: jobData.address1,
        addressLine2: jobData.address2,
        city: jobData.city,
        state: jobData.stateCode,
        zipCode: jobData.zipCode,
        serviceRequestType: jobType,
        startingDate: moment(requestedDate).format("YYYYMMDD"),
        customerType: accountDetails.vztSegment,
        firstName: fName,
        lastName: lName,
        accountName: accountDetails.customerName,
        jobDuration: apptLength * 60,
        serviceJobId: reschedule ? jobData.serviceJobId : "",
        billingCountry: accountDetails.billingCountry,
      },
    };
    handlePercentCalculation(false);
    setSpinner(true);
    if (internationalFlag) {
      data.Body.isRoundTrip = distanceCalculationType == "return" ? "Y" : "N";
    }

    let response = await getSchedule(jobId, data);
    if (response.status === 200 && response?.data?.data.transactionId !== "") {
      getTimeSlots(response.data.data.transactionId, false);
      setTransactionId(response.data.data.transactionId);
    } else {
      clearSpinner();
      setSpotEnabledDates([]);
      setdatesNeeded("");
      notify(
        <>
          <span>No availability at this time. Please follow the fall back process by clicking </span>{" "}
          <TextLink
            href={kbArticleLink}
            size="small"
            type="inline"
            tabIndex="0"
            role="link"
            ariaLabel="kbArticleLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </TextLink>
        </>,
        "error"
      );
    }
  };

  const getTimeSlots = async (transactionId, isMore = false) => {
    isMore && handlePercentCalculation(true);
    isMore && setSpinner(true);
    let response = await getSlots(transactionId, isMore);
    if (response.status === 200 && response?.data?.data.length) {
      let availableDateInfoResponse = [];
      response.data.data.map((timeSlots) => {
        let allSlots = timeSlots.availabilityDates.availableDateInfo;
        allSlots.map((slots) => {
          slots["vendorId"] = timeSlots.vendorId;
          slots["erpVendorId"] = timeSlots.erpVendorId;
        });
        availableDateInfoResponse.push(...allSlots);
      });
      let responseLength = availableDateInfoResponse.length;
      !isMore &&
        setTimeout(
          () => {
            setAdditionalSlotsBtn(true);
          },
          responseLength ? 5000 : 0
        );
      isMore && responseLength > 0 ? responseLength === oldResponseLength && setSameResponse(true) : setOldResponseLength(responseLength);
      let datesNeeded = maxBy(response.data.data, "datesNeeded");
      datesNeeded = datesNeeded?.datesNeeded ? datesNeeded.datesNeeded : "10";
      setdatesNeeded(datesNeeded);
      let availableDates = availableDateInfoResponse.map((date) => {
        if (
          moment(date.availableDate).format("MM/DD/YYYY") >= requestedDate &&
          moment(date.availableDate).format("MM/DD/YYYY") < moment(requestedDate).add(datesNeeded, "days").format("MM/DD/YYYY")
        ) {
          return moment(date.availableDate).format("MM-DD-YYYY");
        }
      });
      setSpotEnabledDates(availableDates);
      let availability = availableDateInfoResponse.filter((response) => {
        return availableDates.includes(moment(response.availableDate).format("MM-DD-YYYY"));
      });
      availability = groupBy(availability, "availableDate");
      setavailableDateInfo(availability);
      let groupedByMiles = groupBy(availability[moment(selectedDate).format("YYYYMMDD")], "totalMiles");
      setAvailabilityDates(sortedMiles(groupedByMiles));
      getMileage();
    } else {
      !isMore && setAdditionalSlotsBtn(true);
      setSpotEnabledDates([]);
      setdatesNeeded("");
      isMore &&
        notify(
          <>
            <span>No availability at this time. Please follow the fall back process by clicking </span>{" "}
            <TextLink
              href={kbArticleLink}
              size="small"
              type="inline"
              tabIndex="0"
              role="link"
              ariaLabel="kbArticleLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </TextLink>
          </>,
          "error"
        );
    }
    clearSpinner();
  };

  const handleAvailableDates = ({ activeStartDate, date, view }) => {
    if (view === "month") {
      let currentMonth = moment(date).month();
      let activeMonth = moment(activeStartDate).month();
      let currentDate = moment(date).format("MM-DD-YYYY");
      let startDate = moment(requestedDate).format("MM-DD-YYYY");
      let datesNeededFromRequestedDate = moment(requestedDate).add(datesNeeded, "days").format("MM-DD-YYYY");

      if (currentDate >= startDate && currentDate < datesNeededFromRequestedDate && !spotenabledDates.includes(currentDate)) {
        return date;
      } else if (currentMonth !== activeMonth) {
        return date;
      }
    }
  };

  const spotAvailableDates = ({ activeStartDate, date, view }) => {
    if (view === "month") {
      let currentMonth = moment(date).month();
      let activeMonth = moment(activeStartDate).month();
      let currentDate = moment(date).format("MM-DD-YYYY");
      let today = moment(new Date()).format("MM-DD-YYYY");
      if (
        Date.parse(currentDate) >= Date.parse(today) &&
        spotenabledDates.includes(moment(date).format("MM-DD-YYYY")) &&
        currentMonth === activeMonth
      ) {
        return "text";
      }
    }
  };

  const handleDate = (e) => {
    let currentDate = moment(e).format("MM/DD/YYYY");
    let datesNeededFromRequestedDate = moment(requestedDate).add(datesNeeded, "days").format("MM/DD/YYYY");
    setSelectedDate(e);
    settotalMiles([]);
    setSlotDetails({});
    setSelectedSlot({});
    setEnableManualScheduleOption(false);
    if (currentDate < requestedDate || currentDate >= datesNeededFromRequestedDate) {
      setAdditionalSlotsBtn(false);
      setSameResponse(false);
      setStartDateChangeModal(
        <StartDateChangeModal
          setConfirmModal={setStartDateChangeModal}
          date={e}
          setRequestedDate={setRequestedDate}
          setConfirmedStartDate={setConfirmedStartDate}
        />
      );
    } else {
      setAdditionalSlotsBtn(true);
    }
    let availability = sortedMiles(groupBy(availableDateInfo[moment(e).format("YYYYMMDD")], "totalMiles"));
    setViewMore(false);
    setViewCount(3);
    setAvailabilityDates(availability);
    setSubmitDisability(true);
  };

  const handleSlot = (selectedTime, index) => {
    setTimeSelected(selectedTime);
    setVendorId(selectedTime.vendorId);
    setErpVendorId(selectedTime.erpVendorId);
    setSelectedSlot(selectedTime);
    settotalMiles({
      totalMiles: distanceUnit === "Miles" ? selectedTime?.totalMiles : selectedTime?.totalKilometers,
      vendor: selectedTime.serviceProviderName,
    });
    setSubmitDisability(false);
    let slotTime = moment(`${selectedTime.availableDate} ${selectedTime.slotStartTime}`).format("MMM D, yyyy h:mm A");
    let durationUnit = durationUnitCalculation(selectedTime);
    setSlotDetails({
      index: index,
      slot: true,
      vendor: selectedTime.serviceProviderName,
      slotTime: `${slotTime} - ${moment(slotTime).add(durationUnit, selectedTime.slotLength).format("h:mm A")}`,
    });
    if (reschedule) {
      setRescheduleReasonModalStatus({
        ...rescheduleReasonModalStatus,
        slotTime: moment(`${selectedTime.availableDate} ${selectedTime.slotStartTime}`).format("dddd, MMMM DD, YYYY [at] h:mm A"),
        timezone: timezone,
      });
    }
    if (mileageType.length <= 0) setSubmitDisability(true);
  };

  let emptyMaterialLineId = [],
    emptyMaterialId = [],
    emptyItemDescription = [],
    emptyQuantity = [],
    emptyProcessingCode = [],
    emptyLineItemUniqueId = [],
    emptyUnitMeasure = [];

  const handleJobSchedule = async (notesToTechnician, notesToVendor) => {
    let salesOrg = source.source === "csp" ? cspDetails?.cspDetails[0]?.Opp_Sales_Org : jobData?.salesOrg?.split(" ")[0];
    updateNewrelicStats();
    let lineItemForMileage;
    let cspCaseDetails = {
      assignedTo: null,
      assignedToEmail: null,
      assignedToEid: null,
    };
    if (source.source?.toLowerCase() === "case") {
      let caseData = caseDetails.caseDetails.length ? caseDetails.caseDetails.find((item) => item.DefaultCase === "1") : {};
      cspCaseDetails = {
        assignedTo: caseData?.CaseOwner || null,
        assignedToEmail: caseData?.CaseOwnerEmail || null,
        assignedToEid: caseData?.CaseOwnerEId || null,
      };
    } else {
      let cspData = cspDetails.cspDetails.length ? cspDetails.cspDetails.find((item) => item.DefaultCSP === "1") : {};
      cspCaseDetails = {
        assignedTo: cspData?.CSPOwner || null,
        assignedToEmail: cspData?.CSPOwnerEmail || null,
        assignedToEid: cspData?.CSPOwnerEId || null,
      };
    }
    let data = {
      Header: {
        requestControlId: "vzc_" + Date.now(),
      },
      Body: {
        header: {
          clientAppName: "VZC",
          clientAppUserName: "ECOM",
        },
        totalKilometers: timeSelected.totalKilometers,
        ignoreCache: false,
        vendorId: vendorId,
        startDate: requestedDate,
        orderNum: jobId,
        serviceJobType: jobType,
        apptInfo: {
          accountName: accountDetails.customerName,
          homePhone: jobData.phone.replace(/\D/g, ""),
          workPhone: "",
          mobilePhone: "",
          primaryPhoneType: "Home",
          emailAddress: jobData.email,
          firstName: firstName,
          lastName: lastName,
          SpecialInstructions: notesToTechnician,
          vendorMessages: notesToVendor,
          installApptSelectedDate: selectedSlot,
        },
        serviceAddress: {
          lastName: lastName,
          firstName: firstName,
          address1: jobData.address1,
          address2: jobData.address2,
          city: jobData.city,
          state: jobData.stateCode,
          postalCode: jobData.zipCode,
          country: jobData.country,
        },
        vehicleInformation: [
          {
            vin: "VIN1243SFDGAGFG1234",
            type: "Truck",
            make: "",
            model: "",
            inventory: [
              {
                name: "VTU1",
                count: "1",
                action: "REMOVE",
              },
              {
                name: "VTU2",
                count: "1",
                action: "INSTALL",
              },
              {
                name: "CAMERA",
                count: "1",
                action: "INSTALL-",
              },
            ],
          },
        ],
        vehicleCount: vehicleCount,
        requestControlID: "",
        languagePref: "E",
        mdnPromo: "Y",
        serviceCost: "60.0",
        eventCorrelationId: "",
        launchType: "FCL",
        addressType: "SFU",
        CustomerType: customerType,
        workTicketNumber: workTicketNumber,
        documentType: source.source === "csp" ? "ZINS" : "ZSRQ",
        salesOrg: salesOrg,
        currencyCode: accountDetails?.currencyCode ?? "",
        distributionChannel: jobData?.distributionChannel ? jobData.distributionChannel : "",
        division: jobData?.division ? jobData.division : "",
        crmReferenceId: source.source === "csp" ? cspDetails?.cspDetails[0]?.CRM_Ref_ID : caseDetails?.caseDetails[0].CaseNumber,
        contractAccount: jobData.contractAccount,
        erpVendorId: erpVendorId,
        salesRepId: accountDetails.employeeId,
        assignedTo: accountDetails.employeeId,
        vendorMessage: notesToVendor, // from job details
        soldToParty: accountDetails.erpBpNumber, //from csp (1ERP BP number)
        shipToParty: accountDetails.erpBpNumber, //from csp (1ERP BP number)
        subStatus: "",
        oldDeliveryNo: "",
        partners: [
          {
            party: accountDetails.resellerBusinessPartnerNumber || accountDetails.erpBpNumber || "",
            partRoleCode: "AG",
          },
          {
            party: accountDetails.erpBpNumber ?? "",
            partRoleCode: "WE",
          },
          {
            party: accountDetails.resellerBusinessPartnerNumber || accountDetails.erpBpNumber || "",
            partRoleCode: "Z6",
            accountName: accountDetails.customerName,
            contactName: jobData.contactName,
            contactPhone: jobData.phone,
            contactEmail: jobData.email,
            street: jobData.address1,
            city: jobData.city,
            state: jobData.stateCode,
            country: jobData.country,
            zip: jobData.zipCode,
            telephone: "",
          },
        ],
        itemDetails: [
          ...jobData.jobDurationDetails.map((job) => {
            let maxLineID = "000000";
            if (job.materialLineID > maxLineID) {
              maxLineID = job.materialLineID;
            }
            lineItemForMileage = ("0000" + (+maxLineID + 10)).slice(-6);
            return {
              materialLineId: job.materialLineID, //id
              materialId: job.materialId,
              itemDescription: job.materialName,
              quantity: job.quantity,
              unitMeasure: job.unitOfMeasure,
              techRefId: job.techRefId,
              processingCode: job.processingCode,
              lineItemUniqueId: job.lineItemUniqueId,
              customerPONumber: source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null, // FROM CSP ONLY
              customerPONumberKut: source.source === "case" ? cspDetails?.cspDetails[0]?.Provider_Order : null, // FROM CASE ONLY
              baseItemId: "", //empty
              esnToBeReturned: "", //empty
              contractId: job?.wtMaterialContractId ? job?.wtMaterialContractId : null,
              sapId: job?.contractCode ? job?.contractCode : null,
              contractName: job?.contractName?.length > 0 ? job?.contractName : null,
              wtMaterialId: job?.wtMaterialId || "",
            };
          }),
          {
            materialLineId: lineItemForMileage, //id
            materialId: selectedMileageType?.materialId ?? "",
            itemDescription: selectedMileageType?.materialName ?? "",
            quantity: Number(totalMiles.totalMiles),
            unitMeasure: "",
            techRefId: "",
            processingCode: "ZTML",
            lineItemUniqueId: jobId + "_" + lineItemForMileage,
            customerPONumber: source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null, // FROM CSP ONLY
            customerPONumberKut: source.source === "case" ? cspDetails?.cspDetails[0]?.Provider_Order : null, // FROM CASE ONLY
            baseItemId: "", //empty
            esnToBeReturned: "", //empty
            contractId: null,
            sapId: null,
            contractName: null,
            wtMaterialId: selectedMileageType?.wtMaterialId ?? "",
          },
        ],
      },
      modifiedBy: accountDetails?.employeeFullName,
      modifiedByEmail: accountDetails?.employeeEmail,
      modifiedByEid: accountDetails?.employeeId,
      assignedTo: cspCaseDetails?.assignedTo,
      assignedToEmail: cspCaseDetails?.assignedToEmail,
      assignedToEid: cspCaseDetails?.assignedToEid,
    };
    let errorMsg = [];
    let missingFieldsArray = [];
    for (const key of Object.keys(data.Body)) {
      if (
        key === "salesOrg" ||
        key === "currencyCode" ||
        key === "distributionChannel" ||
        key === "division" ||
        key === "crmReferenceId" ||
        key === "erpVendorId" ||
        key === "contractAccount" ||
        key === "assignedTo"
      ) {
        if (data.Body[key] === "" || data.Body[key] === undefined || data.Body[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    for (const key of Object.keys(data.Body.serviceAddress)) {
      if (key === "address1" || key === "city" || key === "state" || key === "postalCode" || key === "country") {
        if (data.Body.serviceAddress[key] === "" || data.Body.serviceAddress[key] === undefined || data.Body.serviceAddress[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    if (missingFieldsArray.length > 0) {
      return setTimeout(() => {
        notify(
          "Unable to schedule job due to missing information. Kindly contact IT team for support. Missing information: " +
            missingFieldsArray.toString() +
            ".",
          "error",
          false
        );
      }, 10);
    } else if (accountDetails.resellerBusinessPartnerNumber === null && accountDetails.erpBpNumber === null) {
      return setTimeout(() => {
        notify(
          "Unable to schedule job due to missing mandatory information (Mandatory: Reseller Business Partner Number). Kindly contact IT team for support.",
          "error",
          false
        );
      }, 10);
    } else {
      data.Body?.itemDetails?.filter((data) => {
        for (const key of Object.keys(data)) {
          if (
            key === "materialLineId" ||
            key === "materialId" ||
            key === "itemDescription" ||
            key === "quantity" ||
            key === "processingCode" ||
            key === "lineItemUniqueId"
          ) {
            if (data[key] === "" || data[key] === null || data[key] === undefined) {
              captureMaterialId(key, data.materialId);
            }
          }
        }
      });
      if (emptyMaterialId.length > 0) errorMsg.push(` ${"Material Id"} is missing for ${emptyMaterialId.toString()}`);
      if (emptyMaterialLineId.length > 0) errorMsg.push(` ${"Material Line Id"} is missing for ${emptyMaterialLineId.toString()}`);
      if (emptyItemDescription.length > 0) errorMsg.push(` ${"Item Description"} is missing for ${emptyItemDescription.toString()}`);
      if (emptyQuantity.length > 0) errorMsg.push(` ${"Quantity"} is missing for ${emptyQuantity.toString()}`);
      if (emptyProcessingCode.length > 0) errorMsg.push(` ${"Processing Code"} is missing for ${emptyProcessingCode.toString()}`);
      if (emptyLineItemUniqueId.length > 0) errorMsg.push(` ${"LineItem Unique Id"} is missing for ${emptyLineItemUniqueId.toString()}`);
      if (emptyUnitMeasure.length > 0) errorMsg.push(` ${"Unit of Measure"} is missing for ${emptyUnitMeasure.toString()}`);

      if (errorMsg.length > 0) {
        return setTimeout(() => {
          notify(
            <>
              {"Unable to update the job status due to below missing information. Kindly contact IT team for support. Missing Information: "}
              {errorMsg.map((msg) => (
                <div key={generate()}>{msg}</div>
              ))}
            </>,
            "error",
            false
          );
        }, 10);
      }
    }
    setIsLoading(true);
    if (timeSelected.totalKilometers) {
      data.Body.totalKilometers = timeSelected.totalKilometers;
    }
    let response = await createSchedule(jobId, data);
    if (response.status === 200 && response?.data?.data?.respMsg?.toLowerCase() === "success") {
      settotalMiles([]);
      setSlotDetails({});
      setSelectedSlot({});
      setSubmitDisability(true);
      notify("Job scheduled successfully", "success");
      setTimeout(() => {
        setNotification(null);
        setIsLoading(false);
        switchTab(2, { data: { jobId: jobId } });
      }, 5000);
    } else if (response && response?.data?.statusCode === "200" && response?.data?.data?.error && Object.keys(response?.data?.data?.error)) {
      setIsLoading(false);
      let errorDetail = response?.data?.data?.error?.statusDetail;
      errorDetail?.length && notify(errorDetail[0]?.errorText, "error");
    } else if (response && response?.data?.statusCode === "404") {
      setIsLoading(false);
      let message = response?.data?.message;
      response?.data?.data ? handleERPError(response?.data, notify) : notify(message, "error");
    } else {
      setIsLoading(false);
      notify("Unable to schedule job due to an unexpected error.", "error");
    }
  };
  const captureMaterialId = (materialName, materialId) => {
    switch (materialName) {
      case "materialLineId": {
        emptyMaterialLineId.push(materialId);
        break;
      }
      case "materialId": {
        emptyMaterialId.push(materialId);
        break;
      }
      case "itemDescription": {
        emptyItemDescription.push(materialId);
        break;
      }
      case "quantity": {
        emptyQuantity.push(materialId);
        break;
      }
      case "processingCode": {
        emptyProcessingCode.push(materialId);
        break;
      }
      case "lineItemUniqueId": {
        emptyLineItemUniqueId.push(materialId);
        break;
      }
      case "unitMeasure": {
        emptyUnitMeasure.push(materialId);
        break;
      }
    }
  };
  const handleReschedule = async (selectedReason) => {
    updateNewrelicStats();
    setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: false });
    let cspCaseDetails = {
      assignedTo: null,
      assignedToEmail: null,
      assignedToEid: null,
    };
    if (source.source?.toLowerCase() === "case") {
      let caseData = caseDetails.caseDetails.length ? caseDetails.caseDetails.find((item) => item.DefaultCase === "1") : {};
      cspCaseDetails = {
        assignedTo: caseData?.CaseOwner || null,
        assignedToEmail: caseData?.CaseOwnerEmail || null,
        assignedToEid: caseData?.CaseOwnerEId || null,
      };
    } else {
      let cspData = cspDetails.cspDetails.length ? cspDetails.cspDetails.find((item) => item.DefaultCSP === "1") : {};
      cspCaseDetails = {
        assignedTo: cspData?.CSPOwner || null,
        assignedToEmail: cspData?.CSPOwnerEmail || null,
        assignedToEid: cspData?.CSPOwnerEId || null,
      };
    }
    let data = {
      Header: {
        transactionId: "VZC_" + Date.now(),
        client_name: "VZC",
        isReschedule: true,
      },
      Body: {
        header: {
          clientAppName: "VZC",
          clientAppUserName: "",
          clientAppUserFirstName: firstName,
          clientAppUserLastName: lastName,
          clientAppUserEmail: jobData.email,
          clientAppUserRole: "REP",
          isReschedule: true,
        },
        vendorId: vendorId,
        startDate: moment(requestedDate).format("MM/DD/YYYY"),
        zipcode: jobData.zipCode,
        orderNum: jobId,
        serviceJobId: serviceJobId,
        rescheduleReasonCode: selectedReason.code,
        comments: "",
        rescheduleReason: selectedReason.description,
        serviceJobType: jobType,
        updateBy: "",
        signature: "",
        vehicleCount: vehicleCount,
        createdBy: accountDetails.sfdcUsername,
        apptInfo: {
          installApptSelectedDate: selectedSlot,
        },
      },
      modifiedBy: accountDetails?.employeeFullName,
      modifiedByEmail: accountDetails?.employeeEmail,
      modifiedByEid: accountDetails?.employeeId,
      assignedTo: cspCaseDetails?.assignedTo,
      assignedToEmail: cspCaseDetails?.assignedToEmail,
      assignedToEid: cspCaseDetails?.assignedToEid,
    };

    setIsLoading(true);
    let response = await rescheduleJob(jobId, data);
    if (response.status === 200) {
      notify("Job Rescheduled successfully", "success");
      setAlertModal(false);
      sessionData(jobId);
      setTimeout(() => {
        setNotification(null);
        setIsLoading(false);
        switchTab(2, { data: { jobId: jobId } });
      }, 5000);
    } else {
      // setSlotDetails({})
      setAlertModal(false);
      setIsLoading(false);
      notify("Unable to reschedule job due to an unexpected error", "error");
    }
  };

  const handleConfirmation = (type) => {
    if (type === "reschedule") {
      getRescheduleReasonList();
    } else {
      setConfirmModal(
        <ConfirmationModal
          setConfirmModal={setConfirmModal}
          type={type}
          reschedule={reschedule}
          selectedSlot={selectedSlot}
          timezone={timezone}
          handleSupplyNotes={type === "cancel" ? handleBookingCancellation : handleJobSchedule}
        />
      );
    }
  };

  const getRescheduleReasonList = async () => {
    updateNewrelicStats();
    setIsLoading(true);
    let response = await getReasonlist("VZC_Reschedule");
    if (response.status === 200 && response?.data?.statusCode === "200") {
      setIsLoading(false);
      setRescheduleReasonModalStatus({ ...rescheduleReasonModalStatus, isModalOpen: true, reasonList: response.data.data });
    } else {
      setIsLoading(false);
      notify("Unable to retrieve reschedule reasons.", "error");
    }
  };

  const handleViewMore = (milesLength) => {
    setViewCount(viewCount + 3);
    if (viewCount >= milesLength) {
      setViewMore(true);
    }
  };

  const handleMoreSlots = () => {
    setEnableManualScheduleOption(true);
    setAdditionalSlotsBtn(false);
    getTimeSlots(transactionId, true);
  };

  const handleBookingCancellation = async (reason) => {
    let data = {
      modifiedBy: accountDetails.employeeFullName ?? "",
      closedReason: reason,
    };
    if (data.modifiedBy === "") {
      return setTimeout(() => {
        notify(
          "Booking cancellation failed due to missing mandatory information (Mandatory: Modified By). Kindly contact IT team for support.",
          "error"
        );
      }, 10);
    }
    setIsLoading(true);
    let response = await cancelBooking(jobId, data);
    if (response.status === 200 && response?.data?.statusCode === "200") {
      setIsLoading(false);
      switchTab(2);
    } else {
      setIsLoading(false);
      notify("Unable to cancel due to an unexpected error.", "error");
    }
  };

  const navigateToManualSchedule = () => {
    addPage(AlternateManualEntryMetadata.id, {
      jobData: {
        ...jobData,
        contractAccount: jobData?.contractAccount,
        isReschedule: reschedule,
        fromAutoSchedule: true,
        salesOrg: jobData?.salesOrg,
      },
      mapUrl: getMapUrl(accountDetails.mapUrl, jobData.latitude, jobData.longitude, accountDetails.customerNumber ?? ""),
    });
  };

  const getMileage = async () => {
    updateNewrelicStats();
    setIsLoading(true);
    let salesOrg = jobData?.salesOrg ? jobData?.salesOrg?.split(" ")[0] : "";
    let response = await getMileageList(salesOrg);
    if (response.status === 200 && response?.data?.data?.length) {
      let mileageList = response.data.data.map((records) => {
        return {
          mileageType: `${records.materialName} - ${records.materialId}`,
          wtMaterialId: records.wtMaterialId,
          materialId: records.materialId,
          materialName: records.materialName,
          salesOrgId: records.salesOrgId,
        };
      });

      let distanceMMID = source?.source == "csp" ? cspDetails?.cspDetails[0].distanceMMID : caseDetails?.caseDetails[0].distanceMMID;
      let defaultMileage = mileageList.filter((records) => records.materialId === distanceMMID)[0];
      setSelectedMileageType(defaultMileage);
      setMileageType(defaultMileage.mileageType);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      notify("Unable to fetch mileage list due to an unexpected error", "error");
    }
  };

  return (
    <>
      {rescheduleReasonModalStatus.isModalOpen && (
        <RescheduleReasonModal
          rescheduleReasonModalStatus={rescheduleReasonModalStatus}
          setRescheduleReasonModalStatus={setRescheduleReasonModalStatus}
          handleReschedule={handleReschedule}
          jobDetails={jobData}
          notify={notify}
        />
      )}
      {confirmModal}
      {startDateChangeModal}
      <div id="Scheduler">
        <Loader active={isLoading} fullscreen />
        {spinner && (
          <div css={customSpinner}>
            <CircularProgressbar
              value={spinnerValue}
              text={`${spinnerValue}%`}
              styles={{ path: { stroke: spinnerValue > 49 ? `green` : `orange` } }}
            />
          </div>
        )}
        <Header>{reschedule ? `Reschedule Job` : `Schedule Job`}</Header>
        {notification}
        <Spacer top="20px" />
        <FlexRow>
          <SmallText bold>Job Type:&nbsp;</SmallText>
          <SmallText>{jobType}</SmallText>
        </FlexRow>
        <FlexRow>
          <SmallText bold>Requested Date:&nbsp;</SmallText>
          <SmallText>{moment(requestedDate).format("MMM D, yyyy")}</SmallText>
        </FlexRow>
        <FlexRow>
          <SmallText bold>Duration:&nbsp;</SmallText>
          <SmallText>{apptLength > 1 ? apptLength + " Hours" : apptLength + " Hour"}</SmallText>
          <SmallText bold>&nbsp;Vehicle Count:&nbsp;</SmallText>
          <SmallText>{vehicleCount}</SmallText>
          {/* <MediumText primitive="span">{apptLength}</MediumText> */}
        </FlexRow>
        <FlexRow>
          <SmallText bold>Work Ticket Number:&nbsp;</SmallText>
          <SmallText>{workTicketNumber}</SmallText>
        </FlexRow>
        <FlexRow>
          <SmallText bold>Job ID:&nbsp;</SmallText>
          <SmallText>{jobId}</SmallText>
        </FlexRow>
        <FlexRow>
          <SmallText bold>Address:&nbsp;</SmallText>
          <SmallText>{address}</SmallText>
        </FlexRow>
        <Spacer top="20px" />
        <MediumText css={{ marginTop: "1rem" }} bold>
          Select Date
        </MediumText>
        <Calendar
          className="react-calendar-double"
          tileDisabled={handleAvailableDates}
          showDoubleView={true}
          showNeighboringMonth={false}
          formatShortWeekday={(locale, date) => ["S", "M", "T", "W", "T", "F", "S"][date.getDay()]}
          calendarType="US"
          tileClassName={spotAvailableDates}
          onChange={(e) => handleDate(e)}
          value={selectedDate}
          minDate={new Date(firstEnabledDate)}
          maxDate={
            new Date(moment(requestedDate).add(datesNeeded, "days").format("MM/DD/YYYY")) > new Date(lastEnabledDate)
              ? new Date(
                  moment(requestedDate)
                    .add(datesNeeded - 1, "days")
                    .format("MM-DD-YYYY")
                )
              : new Date(lastEnabledDate)
          }
        />
        <Spacer top="20px" />

        <FlexRow tabIndex={0}>
          <SmallText>For availability dates&nbsp;</SmallText>
          {new Date(requestedDate) > new Date(firstEnabledDate) && (
            <>
              <SmallText bold>before&nbsp;</SmallText>
              <SmallText css={{ textDecoration: "underline" }}>{moment(new Date(requestedDate)).format("MMM D, yyyy")}</SmallText>
            </>
          )}

          {new Date(requestedDate) < new Date(lastEnabledDate) &&
            new Date(moment(new Date(requestedDate)).add(datesNeeded, "days").format("MM/DD/YYYY")) <= new Date(lastEnabledDate) && (
              <>
                {new Date(requestedDate) > new Date(firstEnabledDate) && <SmallText>&nbsp;or&nbsp;</SmallText>}
                <SmallText bold>after&nbsp;</SmallText>
                <SmallText css={{ textDecoration: "underline" }}>
                  {datesNeeded
                    ? moment(requestedDate)
                        .add(datesNeeded - 1, "days")
                        .format("MMM D, yyyy")
                    : moment(requestedDate).format("MMM D, yyyy")}
                </SmallText>
              </>
            )}
          <SmallText>,&nbsp;please click on any of the dates in bold.</SmallText>
        </FlexRow>
        <div tabIndex={0} css={{ marginTop: "1rem" }}>
          <SmallText>Availability Shown {datesNeeded ? datesNeeded + " days" : "1 day"} from Requested Date</SmallText>
          <Spacer top="10px" />
        </div>

        {enableManualScheduleOption && !reschedule && availableDateInfo[moment(selectedDate).format("YYYYMMDD")] && (
          <div css={{ display: "flex", flexDirection: "column" }}>
            <SmallText>For Alternative scheduling needs, please follow manual scheduling:</SmallText>
            <SmallText css={{ textDecoration: "underline", marginTop: "0.1rem", cursor: "pointer" }} onClick={navigateToManualSchedule}>
              (Click here for alternative process)
            </SmallText>
          </div>
        )}

        <p tabIndex={0}>
          <b>{!reschedule && `Choose a Time`}</b>
        </p>

        <p tabIndex={0}>
          <b>Times are displayed in your customer&apos;s time zone.</b>
        </p>
        <Spacer top="20px" />
        <div style={reschedule ? { marginBottom: slotDetails.slot ? "265.26px" : "91px" } : { marginBottom: slotDetails.slot ? "185.65px" : "91px" }}>
          <>
            {availableDateInfo[moment(selectedDate).format("YYYYMMDD")] ? (
              <>
                {availabilityDates.map((miles, index) => {
                  let mile = Object.keys(miles)[0];
                  let kiloMeters = miles[mile][0].totalKilometers;
                  let morning = [];
                  let afternoon = [];
                  let availableSlots = viewMore ? availabilityDates : availabilityDates.slice(0, viewCount);
                  availableSlots.includes(miles) &&
                    Object.values(miles).map((slots) => {
                      slots.map((slot) => {
                        slot.slotStartTime < "1200" ? morning.push(slot) : afternoon.push(slot);
                      });
                    });
                  return (
                    <React.Fragment key={mile}>
                      {availableSlots.includes(miles) && (
                        <FlexColumn>
                          <FlexRow
                            tabIndex={0}
                            style={{ backgroundColor: "lightgray", padding: "4px 5px 0px 5px", width: "fit-content", marginBottom: "5px" }}
                          >
                            <UltraMediumText marginBottom="5px">
                              {distanceUnit === "Miles" ? mile : kiloMeters} {distanceUnit} {distanceCalculationType}
                            </UltraMediumText>
                          </FlexRow>
                          <SlotsContainer style={{ marginBottom: "1rem" }}>
                            {morning.length > 0 && (
                              <>
                                <p css={{ color: "#747676", fontSize: "11px", fontWeight: "600", margin: "0" }} tabIndex={0}>
                                  Morning
                                </p>
                                {sortBy(morning, "slotStartTime").map((availability, indexValue) => {
                                  return (
                                    <div key={indexValue}>
                                      <SlotButtons
                                        indexValue={indexValue}
                                        slotDetails={slotDetails}
                                        reschedule={reschedule}
                                        availability={availability}
                                        jobData={jobData}
                                        handleSlot={handleSlot}
                                      />
                                    </div>
                                  );
                                })}
                              </>
                            )}
                            <div style={{ clear: "both" }}></div>
                            {afternoon.length > 0 && (
                              <>
                                <p tabIndex={0} css={{ color: "#747676", fontSize: "11px", fontWeight: "600", margin: "0" }}>
                                  Afternoon
                                </p>
                                {sortBy(afternoon, "slotStartTime").map((availability, indexValue) => {
                                  return (
                                    <div key={indexValue}>
                                      <SlotButtons
                                        indexValue={indexValue}
                                        slotDetails={slotDetails}
                                        reschedule={reschedule}
                                        availability={availability}
                                        jobData={jobData}
                                        handleSlot={handleSlot}
                                      />
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </SlotsContainer>
                          {index !== availabilityDates.length - 1 && <Line type="xLight" inverted={false} />}
                          {index !== availabilityDates.length - 1 && <Spacer top="20px"></Spacer>}
                        </FlexColumn>
                      )}
                    </React.Fragment>
                  );
                })}
                {viewCount < availabilityDates.length && (
                  <SmallText
                    css={{ textDecoration: "underline", cursor: "pointer", marginBottom: "10px" }}
                    onClick={() => handleViewMore(availabilityDates.length)}
                    tabIndex={0}
                  >
                    View more times
                  </SmallText>
                )}
              </>
            ) : (
              <>
                <div css={{ display: "flex" }}>
                  <UltraMediumText>No times available for the appointment length&nbsp;</UltraMediumText>
                  {!reschedule && (
                    <SmallText css={{ textDecoration: "underline", marginTop: "0.1rem", cursor: "pointer" }} onClick={navigateToManualSchedule}>
                      (Click here for alternative process)
                    </SmallText>
                  )}
                </div>
                <SmallText>Try selecting a different date.</SmallText>
                {!spotenabledDates.length && additionalSlotsBtn && (
                  <SLButton primary size="large" onClick={handleMoreSlots}>
                    Try Again
                  </SLButton>
                )}
              </>
            )}
            {spotenabledDates.length > 0 && additionalSlotsBtn && (
              <FlexRow>
                <div tabIndex={0} style={{ padding: "1rem 1rem 0 0" }}>
                  Do you want to see additional slots ?
                </div>
                <SLButton tabIndex={0} aria-label="Yes" primary size="large" onClick={handleMoreSlots}>
                  Yes
                </SLButton>
                <SLButton secondary tabIndex={0} aria-label="no" size="large" onClick={() => setAdditionalSlotsBtn(false)}>
                  No
                </SLButton>
              </FlexRow>
            )}
            {sameResponse && (
              <MediumText tabIndex={0} bold size="large">
                Sorry, there are no additional slots available at this moment.
              </MediumText>
            )}
          </>
        </div>
        <div ref={footerRef}>
          <Line type="xLight" inverted={true} />
          <Footer>
            {slotDetails.slot && (
              <>
                <MediumText tabIndex={0} css={{ marginTop: "1rem" }} bold>
                  Confirm Details
                </MediumText>
                <FlexRow>
                  <div>
                    <UltraMediumText tabIndex={0} marginBottom="0px" css={{ marginTop: "1rem", marginBottom: "5px" }}>
                      {totalMiles.totalMiles} {distanceUnit} {distanceCalculationType}
                    </UltraMediumText>
                    <UltraMediumText tabIndex={0} fontWeight="normal">
                      {slotDetails.slotTime} {timezone}
                    </UltraMediumText>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <CustomInput width="260px" type="text" label="Mileage Type" value={mileageType} disabled paddingRight="5px" />
                  </div>
                </FlexRow>
              </>
            )}
            <FlexRow css={{ marginTop: "1rem" }}>
              <SLButton primary disabled={submitDisability} onClick={() => handleConfirmation(reschedule ? `reschedule` : `schedule`)}>
                {reschedule ? `Reschedule` : `Schedule`}
              </SLButton>
              <SLButton secondary onClick={() => handleConfirmation("cancel")}>
                Cancel
              </SLButton>
            </FlexRow>
          </Footer>
        </div>
      </div>
    </>
  );
}
export const ScheduleJobMetadata = {
  name: "Schedule Job",
  id: "schedule_job",
  component: lazy(() => import("./ScheduleJob")),
  route: "/schedule_job",
};
export const RescheduleJobMetadata = {
  name: "Reschedule Job",
  id: "Reschedule_job",
  component: lazy(() => import("./ScheduleJob")),
  route: "/schedule_job",
};
