import { lazy } from "react";
import { CheckinProvider } from "../context/checkinContext";
import { CreateVehicleProvider } from "../context/createVehicleContext";
import { UploadProvider } from "../context/uploadContext";
import { AccountDetailsProvider } from "../C4S Decommission - Replacement/context/accountDetailsContext";
import { AccountDetailsProvider as AccountDetailsProviderV1 } from "../Scheduling Portal V1/context/accountDetailsContext";
import { ActivateBatchMetadata } from "./ActivateBatch";
import { ActivateViewBatchMetadata } from "./ActivateBatch/ViewBatch";
import { ViewServiceRequestPage } from "./checkin/ViewServiceRequests";
import { ViewServiceRequestsInfoPage } from "./checkin/ViewServiceRequestsInfo";
import { EditWorkTicketPage } from "./checkin/EditWorkTicket";
import { CheckEsnStatusPage } from "./checkin/CheckEsnStatus";
import { CheckStatusMetadata } from "./CheckStatusPage";
import { ViewUnitMetadata } from "./CreateVehicles/ViewUnit";
import { DisplayAccountUnitsMetadata } from "./SwapDevices/DisplayAccountUnits";
import { SwapDevicesMetadata } from "./SwapDevices/SwapDevicesFinal";
import { SwapSimMetadata } from "./SwapDevices/SwapSim";
import { UpdateManualActivationMetaData } from "./UpdateActiveUnits/UpdateManualActivation.js";
import { UpdateBatchData } from "./UploadUnits/UpdateBatch";
import { ViewBatchMetadata } from "./UploadUnits/ViewBatches";
//import { LookupMetaData } from "./UnassignCamera/Lookup";
import { WorkOrderData } from "./CreateCamera/WorkOrder";
import { CreateNewJobMetadata } from "../C4S Decommission - Replacement/pages/OpenJobs/CreateNewJob";
import { CreateNewJobMetadata as CreateNewJobMetadataV1 } from "../Scheduling Portal V1/pages/OpenJobs/CreateNewJob";
import { CreateAdditionalFeesMetadata } from "../C4S Decommission - Replacement/pages/OpenJobs/CreateAdditionalFees";
import { RescheduleJobMetadata, ScheduleJobMetadata } from "../C4S Decommission - Replacement/pages/OpenJobs/ScheduleJob";
import {
  RescheduleJobMetadata as RescheduleJobMetadataV1,
  ScheduleJobMetadata as ScheduleJobMetadataV1,
} from "../Scheduling Portal V1/pages/OpenJobs/ScheduleJob";
import { SchedulerReportMetadata } from "../C4S Decommission - Replacement/pages/OpenJobs/Report/SchedulerReport";
import { SchedulerReportMetadata as SchedulerReportMetadataV1 } from "../Scheduling Portal V1/pages/OpenJobs/Report/SchedulerReport";
import Milestone, { MilestoneMetadata } from "./Orders/milestone";
import { VehicleListTableMetadata } from "../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/VehicleListTable";
import { VehicleIntakeMetadata } from "../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/VehicleIntake";
import { ViewWorkTicketMetadata } from "../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/ViewWorkTicket";
import { C4SProvider } from "../C4S Decommission - Replacement/context/c4sContext";
import { ReportsMilestoneMetadata } from "./Reports/ReportsMilestone";
import { AddAVehicleMetadata, EditDetailsMetadata } from "../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/AddAVehicle";
import { AssignCameraProvider } from "../context/cameraContext";
import { Privileges } from "../constants/PrivilegeConstants";
import { ATPSummaryCheckMetaData } from "../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/ATPSummaryCheck";
import { WorkViewItemTableMetadata } from "../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/WorkViewItemTable";
import { AddHardwareMetadata } from "../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/AddHardware";
import { AlternateManualEntryMetadata } from "../C4S Decommission - Replacement/pages/OpenJobs/AlternateManualSchedule";
import { AlternateManualEntryMetadata as AlternateManualEntryMetadataV1 } from "../Scheduling Portal V1/pages/OpenJobs/AlternateManualSchedule";
import { IndividualReturnMetadata } from "../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/IndividualReturn";
import { BulkCheckInResultsPage } from "./checkin/BulkCheckInResults";

import { ReturnEquipmentProvider } from "../context/returnEquipmentContext";
import { InstallersMetadata } from "../C4S Decommission - Replacement/pages/AdminVendorManagement/Installers";
import { VendorsMetadata } from "../C4S Decommission - Replacement/pages/AdminVendorManagement/Vendors";
import { DetailedWorkTicketViewMetadata } from "../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/DetailedWorkTicketView";
import AssignAndConfirmCamera, { AssignAndConfirmCameraMetadata } from "./Camera/AssignControlUnit/AssignAndConfirmCamera.jsx";
// Declare the metadata of the newly created page here
// Note: The id should be unique as it is a unique identifier for corresponding page

export const RETURN_EQUIPMENT_PAGE = {
  name: "Return Equipment",
  id: "return_equipment",
  component: lazy(() => import("./ReturnEquipment")),
  route: "/ReturnEquipment",
  context: ReturnEquipmentProvider,
  subPages: [],
  privileges: Privileges.ENABLE_RETURN_EQUIPMENT,
};

export const CHECKIN_PAGE = {
  name: "Checkin",
  id: "Checkin",
  component: lazy(() => import("./checkin")),
  route: "/CheckIn",
  context: CheckinProvider,
  subPages: [ViewServiceRequestPage, ViewServiceRequestsInfoPage, EditWorkTicketPage, CheckEsnStatusPage, BulkCheckInResultsPage],
  privileges: Privileges.ENABLE_DEVICE_CHECKIN,
};

export const REVEAL_SEARCH_UNIT = {
  name: "Search Reveal Unit",
  id: "SearchRevealUnit",
  component: lazy(() => import("./SearchRevealUnit")),
  route: "/SearchRevealUnit",
  privileges: Privileges.ENABLE_REVEAL_SEARCH,
  disabled: false,
};

export const UPLOAD_UNITS = {
  name: "Upload Units",
  id: "upload_units",
  context: UploadProvider,
  component: lazy(() => import("./UploadUnits")),
  subPages: [ViewBatchMetadata, UpdateBatchData],
  route: "/upload_units",
  privileges: Privileges.ENABLE_UPLOAD_UNITS,
};

export const ADD_REFURBISHED_UNITS = {
  name: "Update Refurbished Units",
  id: "add_refurbised_units",
  component: lazy(() => import("./AddRefurbishedUnits")),
  subPages: [],
  route: "/add_refurbised_units",
};

export const SWAP_DEVICES = {
  name: "Swap Devices",
  id: "swap_devices",
  component: lazy(() => import("./SwapDevices")),
  subPages: [SwapDevicesMetadata, SwapSimMetadata, DisplayAccountUnitsMetadata],
  route: "/swap_devices",
  privileges: Privileges.ENABLE_SWAP_DEVICE,
};

export const UPDATE_ACTIVE_UNITS = {
  name: "Update Active Units",
  id: "update_active",
  context: UploadProvider,
  component: lazy(() => import("./UpdateActiveUnits")),
  route: "/WUnit",
  subPages: [UpdateManualActivationMetaData, CheckStatusMetadata, ActivateBatchMetadata, ActivateViewBatchMetadata],
  privileges: Privileges.ENABLE_UPDATE_ACTIVATE_UNITS,
};
export const CREATE_VEHICLES_PAGE = {
  name: "Create Vehicles",
  id: "CreateVehicles",
  context: CreateVehicleProvider,
  component: lazy(() => import("./CreateVehicles")),
  subPages: [ViewUnitMetadata],
  route: "/CreateVehicles",
  privileges: Privileges.ENABLE_CREATE_VEHICLE,
  disabled: true,
};
export const CREATE_ACCOUNT = {
  name: "Create Account",
  id: "create_account",
  component: lazy(() => import("./CreateAccountInReveal/CreateAccount")),
  route: "/create_account",
};
export const UPDATE_ACCOUNT = {
  name: "Update Reveal Account",
  id: "update reveal",
  component: lazy(() => import("./DefaultPage")),
  route: "/update_reveal",
};

export const METRIC_DASHBOARD = {
  breadcrumbs: false,
  name: "Metric Dashboard",
  id: "metric dashboard",
  component: lazy(() => import("../C4S Decommission - Replacement/pages/MetricDashboard/MetricDashboardPage")),
  route: "/connect360/metrics-dashboard",
};

export const ADMIN_VENDOR_MANAGEMENT = {
  breadcrumbs: false,
  name: "Admin Vendor Management",
  id: "admin vendor management",
  component: lazy(() => import("../C4S Decommission - Replacement/pages/AdminVendorManagement/VendorManagementPage")),
  route: "/connect360/vendor-management",
};

export const MOVE_VEHICLE = {
  name: "Move Vehicle to a new Account",
  id: "move_vehicle_to_a",
  component: lazy(() => import("./DefaultPage")),
  route: "/move_vehicle_to",
};
export const MOVE_VEHICLE_TEST = {
  name: "Move Vehicles to a Test/Limbo",
  id: "move_vehicle",
  component: lazy(() => import("./DefaultPage")),
  route: "/move_vehicle",
};

export const DECOMMISSION_VEHICLES = {
  name: "Decommission Vehicles",
  id: "decommission_vehicle",
  component: lazy(() => import("./DecommissionVehicles")),
  route: "/decommission_vehicle",
};

export const FAST_START_DATA = {
  name: "Fast Start Data",
  id: "fast_start_data",
  component: lazy(() => import("./DefaultPage")),
  route: "/fast_start_data",
};

export const RENEW_ORDER = {
  name: "Renew Order",
  id: "renew_order",
  component: lazy(() => import("./DefaultPage")),
  route: "/renew_order",
};

export const TRANSFER_TECH_REFID = {
  name: "Transfer TechRefID",
  id: "transfer_techrefid",
  component: lazy(() => import("./DefaultPage")),
  route: "/transfer_techrefid",
};

export const CREATE_ORDER = {
  name: "Orders",
  id: "create_order",
  component: lazy(() => import("./Orders/CreateOrder")),
  subPages: [MilestoneMetadata],
  route: "/orders",
  privileges: Privileges.ENABLE_ORDER_PAGE,
};

export const INCOMPLETE_VTU_CHECK_INS = {
  name: "Incomplete VTU Check-Ins",
  id: "InComplete_VTU",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const CREATE_EVENTS = {
  name: "Event Viewer",
  id: "create_event",
  component: lazy(() => import("./Events")),
  route: "/events",
  privileges: Privileges.ENABLE_ORDER_PAGE,
};

export const DISPLAY_SWAP_REPORT = {
  name: "Display_Swap_Report",
  id: "Display Swap Report",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};
export const DECOMMISSIONED_REPORT = {
  name: "Decommissioned Report",
  id: "Decommissioned_Report",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const COMPLETE_VTU_CHECK_INS = {
  name: "Complete VTU Check-Ins",
  id: "Complete_VTU_Check-Ins",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const ASSIGNED_CAMERAS_REPORTS = {
  name: "Assigned Camera Report",
  id: "Assigned_Camera_Report",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const UNASSIGNED_CAMERAS_REPORTS = {
  name: "Unassigned Camera Report",
  id: "Unassigned_Camera_Report",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const DECOMMISSIONED_CAMERAS_REPORT = {
  name: "Decommissioned Camera Report",
  id: "Decommissioned_Camera_Report",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const SWAP_CAMERA_REPORT = {
  name: "Swap Camera Report",
  id: "Swap_Camera_Report",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const CREATE_CAMERA_REPORT = {
  name: "Create Camera Report",
  id: "Create_Camera_Report",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};
export const CREATE_CAMERA = {
  name: "Create Camera",
  id: "create camera",
  component: lazy(() => import("./CreateCamera")),
  subPages: [WorkOrderData],
  route: "/CreateCamera",
};
export const OPPORTUNITY_REPORT = {
  name: "Opportunity Report",
  id: "Opportunity_Report",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const RENEWAL_REPORT = {
  name: "Renewal Report",
  id: "Renewal_Report",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const DISCONNECT_REPORT = {
  name: "Disconnect Report",
  id: "Disconnect_Report",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const AUDIT_LOG = {
  name: "Audit Log",
  id: "Audit_Log",
  component: lazy(() => import("./AuditLog")),
  route: "/AuditLog",
};

export const ATTRIBUTE_UPDATE_VIEWER = {
  name: "Attribute Update Viewer",
  id: "Attribute_Update_Viewer",
  component: lazy(() => import("./AttributeUpdateViewer/index.js")),
  route: "/AttributeUpdateViewer",
  privileges: Privileges.ENABLE_POST_SALE_ATTRIBUTE_VIEWER,
};

export const SEARCH_REVEAL_UNIT = {
  name: "Search Reveal Unit",
  id: "Search_Reveal_Unit",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const SAP_OUTBOX = {
  name: "SAP Outbox",
  id: "SAP_Outbox",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const EVENT_VIEWER = {
  name: "Event Viewer",
  id: "Event_Viewer",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const DYNAMIC_MAPPING = {
  name: "Dynamic Mapping",
  id: "Dynamic_Mapping",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const STUCK_ORDERS = {
  name: "Stuck Orders",
  id: "Stuck_Orders",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const CHECK_ACCOUNT_STATUS = {
  name: "Check Account Status",
  id: "Check_Account_Status",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const DATABASE_BROWSER = {
  name: "Database Browser",
  id: "Database_Browser",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const DATABASE_EDITOR = {
  name: "Database Editor",
  id: "Database_Editor",
  component: lazy(() => import("./DefaultPage")),
  route: "/fourth_page",
};

export const HOME_PAGE = {
  breadcrumbs: false,
  name: "Home",
  id: "Home_Page",
  component: lazy(() => import("./WelcomePage")),
  route: "/home",
};
export const UNASSIGN_CAMERA = {
  name: "Unassign Camera",
  id: "Unassign_Camera",
  component: lazy(() => import("./Camera/UnassignCamera")),
  //subPages: [LookupMetaData],
  context: AssignCameraProvider,
  route: "/unassignCamera",
  privileges: Privileges.ENABLE_UNASSIGN_CAMERAS,
};
export const OPEN_JOBS = {
  name: "Open Jobs",
  id: "Open_Jobs",
  component: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/navigation/TabNavigation")),
  mainComponent: lazy(() => import("../C4S Decommission - Replacement/pages/OpenJobs/SchedulingPortalLandingPage")),
  context: AccountDetailsProvider,
  subPages: [
    CreateNewJobMetadata,
    CreateAdditionalFeesMetadata,
    ScheduleJobMetadata,
    RescheduleJobMetadata,
    SchedulerReportMetadata,
    AlternateManualEntryMetadata,
  ],
  route: "/connect360/schedules",
  breadcrumbs: false,
  tabIndex: 2,
  tabLabel: "Scheduling Portal",
  privileges: Privileges.ENABLE_SCHEDULING_PORTAL_NEW_MENU,
};
export const MANUAL_JOB_ENTRY = {
  name: "Manual Job Entry",
  id: "Manual_Job_Entry",
  component: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/navigation/TabNavigation")),
  mainComponent: lazy(() => import("../C4S Decommission - Replacement/pages/OpenJobs/AlternateManualSchedule")),
  route: "/connect360/schedules/manualJobEntry",
  breadcrumbs: false,
  privileges: Privileges.ENABLE_SCHEDULING_PORTAL_MENU,
};
export const AUDIT_EVENTS_SCHEDULER = {
  name: "Scheduler Events",
  id: "viewSchedulerEvents",
  component: lazy(() => import("./SL2.0-Schedulers/AuditEvents")),
  route: "/view_scheduler_events",
  privileges: Privileges.ENABLE_SCHEDULER_EVENTS,
};

export const RBAC_ACCESS = {
  name: "RBAC Editor",
  id: "RBACEditor",
  component: lazy(() => import("./SL2.0-RBAC/RbacEditor")),
  route: "/rbac_editor",
  privileges: Privileges.ENABLE_RBAC_EDITOR,
};

export const ORDER_WORKFLOW_RETRY = {
  name: "Order Workflow Retry",
  id: "Order_Workflow_Retry",
  component: lazy(() => import("./OrderWorkflowRetry")),
  route: "/order_workflow_retry",
  privileges: Privileges.ENABLE_PAGES_ORDER_MILESTONES_RETRY,
};

export const VENDOR_MANAGEMENT = {
  name: "Vendor Management",
  id: "Vendor_Management",
  component: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/navigation/AdminPortal")),
  mainComponent: lazy(() => import("../C4S Decommission - Replacement/pages/VendorManagement/ManageVendorPage")),
  route: "/connect360/schedules/vendor_management",
  breadcrumbs: false,
};

export const BUILD_PROCESSING_CODE = {
  name: "Build Processing Code",
  id: "Build_Processing_Code",
  component: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/navigation/AdminPortal")),
  mainComponent: lazy(() => import("../C4S Decommission - Replacement/pages/ProcessingCode/BuildProcessingCodePage")),
  route: "/connect360/schedules/build_processing_code",
  breadcrumbs: false,
};

export const Vehicle_Intake = {
  name: "Vehicle Intake",
  id: "Vehicle_Intake",
  subPages: [
    VehicleIntakeMetadata,
    VehicleListTableMetadata,
    AddAVehicleMetadata,
    EditDetailsMetadata,
    ATPSummaryCheckMetaData,
    AddHardwareMetadata,
    IndividualReturnMetadata,
  ],
  component: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/navigation/TabNavigation")),
  mainComponent: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/C4SLandingPage")),
  context: C4SProvider,
  route: "/connect360/vehicle-intake",
  breadcrumbs: false,
  tabIndex: 0,
  tabLabel: "Vehicle Intake",
  privileges: Privileges.ENABLE_WORKTICKET_PORTAL_MENU,
};

export const View_Work_Ticket = {
  name: "View Work Ticket",
  id: "View_Work_Ticket",
  subPages: [ViewWorkTicketMetadata, VehicleListTableMetadata, WorkViewItemTableMetadata, DetailedWorkTicketViewMetadata],
  component: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/navigation/TabNavigation")),
  mainComponent: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/C4SLandingPage")),
  context: C4SProvider,
  route: "/connect360/vehicle-intake/workticket",
  breadcrumbs: false,
  tabIndex: 1,
  tabLabel: "Work Ticket",
  privileges: Privileges.ENABLE_WORKTICKET_PORTAL_MENU,
};

export const REPORTS = {
  name: "Order Reports",
  id: "order_reports",
  component: lazy(() => import("./Reports")),
  subPages: [ReportsMilestoneMetadata],
  route: "/order_reports",
  privileges: Privileges.ENABLE_ORDER_REPORT,
};
export const DEVICE_REPORTS = {
  name: "Device Reports",
  id: "device_reports",
  component: lazy(() => import("./Reports/DeviceReports")),
  route: "/device_reports",
  privileges: Privileges.ENABLE_DEVICE_REPORT,
};

export const SHIPMENT_DATA = {
  name: "Shipment Data",
  id: "shipment_data",
  component: lazy(() => import("./Reports/ShipmentReports")),
  route: "/shipment_reports",
  privileges: Privileges.ENABLE_DEVICE_REPORT,
};

export const REPORTS_MILESTONE_METADATA = {
  name: "Order Details",
  id: "reports_order_details",
  component: lazy(() => import("./Reports/ReportsMilestone.jsx")),
  route: "/reports_order_details",
  directlyAccessible: false,
};

export const ASSIGN_CAMERA = {
  name: "Assign Camera",
  id: "assign_camera",
  component: lazy(() => import("./Camera/AssignCamera/index.jsx")),
  route: "/assign_camera",
  context: AssignCameraProvider,
  privileges: Privileges.ENABLE_ASSIGN_CAMERAS,
};

export const DECOMM_CAMERA = {
  name: "Decommission Camera",
  id: "decomm_camera",
  component: lazy(() => import("./Camera/DecommCamera/index.jsx")),
  route: "/decomm_camera",
  privileges: Privileges.ENABLE_DECOMM_CAMERAS,
};

export const ASSIGN_CAMERA_V2 = {
  name: "Assign Camera V2",
  id: "assign_camera_v2",
  component: lazy(() => import("./Camera/AssignControlUnit/index.jsx")),
  route: "/assign_camera_v2",
  subPages: [AssignAndConfirmCameraMetadata],
  context: AssignCameraProvider,
  privileges: Privileges.ENABLE_ASSIGN_CAMERAS_EVC,
};

export const CREATE_CAMERA_PAGE = {
  name: "Create Camera",
  id: "create_camera",
  component: lazy(() => import("./Camera/CreateCamera/index.jsx")),
  route: "/create_camera",
  privileges: Privileges.ENABLE_CREATE_CAMERA,
};

export const CONNECT_360 = {
  name: "CONNECT 360",
  id: "CONNECT_360",
  subPages: [],
  component: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/navigation/TabNavigation")),
  route: "/connect360",
  breadcrumbs: false,
};

export const MMID_PORTAL_METADATA = {
  name: "MMID Portal",
  id: "MMID_Portal",
  subPages: [],
  component: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/navigation/AdminPortal")),
  mainComponent: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/MMIDPortal/MMIDPage")),
  context: C4SProvider,
  route: "/connect360/mmid-portal",
  breadcrumbs: false,
  // tabIndex: 3,
  tabLabel: "MMID Portal",
  privileges: Privileges.ENABLE_C360_ADMIN_MMID_PORTAL,
};

export const MMID_PORTAL_NEW_METADATA = {
  name: "MMID Portal New",
  id: "MMID_Portal_New",
  subPages: [],
  component: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/navigation/AdminPortal")),
  mainComponent: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/MMIDPortalNew/MMIDPage")),
  context: C4SProvider,
  route: "/connect360/mmid-portal-new",
  breadcrumbs: false,
  // tabIndex: 3,
  tabLabel: "MMID Portal New",
  privileges: Privileges.ENABLE_C360_ADMIN_MMID_PORTAL,
};

export const SWAP_CAMERA = {
  name: "Swap Camera",
  id: "swap_camera",
  component: lazy(() => import("./Camera/SwapCamera/index.jsx")),
  route: "/swap_camera",
  privileges: Privileges.ENABLE_SWAP_CAMERAS,
};

export const SWAP_CONTROL_UNIT = {
  name: "Swap Control Unit",
  id: "swap_control_unit",
  component: lazy(() => import("./Camera/SwapControlUnit/index.jsx")),
  route: "/swap_control_unit",
  privileges: Privileges.ENABLE_SWAP_CONTROL_UNITS,
};

export const SWAP_CAMERA_V2 = {
  name: "Swap Camera V2",
  id: "swap_camera_v2",
  component: lazy(() => import("./Camera/SwapCamera/SwapCameraV2.jsx")),
  route: "/swap_camera_v2",
  privileges: Privileges.ENABLE_SWAP_CAMERAS,
};

export const VENDOR_MANAGEMENT_LANDING_METADATA = {
  name: "VENDOR MANAGEMENT LANDING",
  id: "VENDOR_MANAGEMENT_LANDING",
  // subPages: [InstallersMetadata, VendorsMetadata],
  component: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/navigation/AdminPortal")),
  mainComponent: lazy(() => import("../C4S Decommission - Replacement/pages/AdminVendorManagement/VendorManagementLandingPage")),
  // context: C4SProvider,
  route: "/connect360/vendors",
  breadcrumbs: false,
  tabLabel: "VENDORS",
  privileges: Privileges.ENABLE_C360_ADMIN_MANUAL_INSTALLER_PORTAL,
};

export const HELP = {
  name: "Help Section",
  id: "help_section",
  component: lazy(() => import("./Help")),
  route: "/help",
};

export const ABOUT = {
  name: "About Section",
  id: "about_section",
  component: lazy(() => import("./About")),
  route: "/about",
};

export const OPEN_JOBSV1 = {
  name: "Open Jobs V1",
  id: "Open_Jobs_V1",
  component: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/navigation/TabNavigation")),
  mainComponent: lazy(() => import("../Scheduling Portal V1/pages/OpenJobs")),
  context: AccountDetailsProviderV1,
  subPages: [CreateNewJobMetadataV1, ScheduleJobMetadataV1, RescheduleJobMetadataV1, SchedulerReportMetadataV1, AlternateManualEntryMetadataV1],
  route: "/connect360/schedules-v1",
  breadcrumbs: false,
  tabIndex: 3,
  tabLabel: <span style={{ margin: "0 10px 0 10px" }}>Scheduling Portal</span>,
  privileges: Privileges.ENABLE_SCHEDULING_PORTAL_MENU,
};

export const VENDOR_MANAGEMENT_V1 = {
  name: "Vendor Management",
  id: "Vendor_Management V1",
  component: lazy(() => import("../Scheduling Portal V1/pages/VendorManagement/ManageVendorPage")),
  route: "/connect360/schedules-v1/vendor_management",
  breadcrumbs: false,
};

export const CONNECT_360_ADMIN = {
  name: "CONNECT 360 ADMIN",
  id: "CONNECT_360_ADMIN",
  subPages: [],
  component: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/navigation/AdminPortal")),
  route: "/connect360/admin",
  breadcrumbs: false,
};

export const MANAGE_DISTANCE_METADATA = {
  name: "Manage Distance",
  id: "Manage_Distance",
  subPages: [],
  component: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/navigation/AdminPortal")),
  mainComponent: lazy(() => import("../C4S Decommission - Replacement/pages/C4S Decommission - Replacement/ManageDistance/ManageDistance")),
  context: C4SProvider,
  route: "/connect360/manage-distance",
  breadcrumbs: false,
  tabLabel: "Manage Distance",
  privileges: Privileges.ENABLE_C360_ADMIN_MMID_PORTAL,
};

export const DEVICE_UPDATER = {
  name: "Device Updater",
  id: "Device_Updater",
  component: lazy(() => import("./DeviceUpdater")),
  route: "/DeviceUpdater",
  privileges: Privileges.ENABLE_DEVICE_UPDATER,
};
