import styled from "@emotion/styled";
import { Col, Grid, Row } from "@vds/grids";
import { Title, Body } from "@vds/typography";
import { Button } from "@vds/buttons";
import { Notification } from "@vds/notifications";

const Flex = styled.div((props) => {
  const style = {
    display: "flex",
    ...props,
  };
  delete style.children;
  return style;
});

/**
 * Flexbox row div
 * @param {Object} props
 * @param {"flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "initial" | "inherit"} props.justifyContent justify-content property
 * @param {"stretch" | "center" | "flex-start" | "flex-end" | "baseline" | "initial" | "inherit"} props.alignItems align-items property
 * @param {"stretch" | "center" | "flex-start" | "flex-end" | "space-between" | "space-around" | "initial" | "inherit"} props.alignContent align-content property
 * @param {number} props.order order property
 * @param {"string"} props.flex flex property
 * @param {"auto" | "stretch" | "center" | "flex-start" | "flex-end" | "baseline" | "initial" | "inherit"} props.alignSelf align-self property
 * @param {"row" | "row-reverse" | "column" | "column-reverse" | "initial" | "inherit"} props.flexDirection flex-direction property
 * @param {"auto" | "initial" | "inherit"} props.flexBasis flex-basis property
 * @param {string} props.flexFlow flex-flow property
 * @param {number | "initial" | "inherit"} props.flexGrow flex-grow property
 * @param {number | "initial" | "inherit"} props.flexShrink flex-shrink property
 * @param {"nowrap" | "wrap" | "wrap-reverse" | "initial" | "inherit"} props.flexWrap flex-wrap property
 * @param {string} props.width flex-width property
 * @param {string} props.height flex-height property
 */
export const FlexRow = (props) => <Flex flexDirection="row" flexWrap="wrap" {...props} />;

/**
 * Flexbox column div
 * @param {Object} props
 * @param {"flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "initial" | "inherit"} props.justifyContent justify-content property
 * @param {"stretch" | "center" | "flex-start" | "flex-end" | "baseline" | "initial" | "inherit"} props.alignItems align-items property
 * @param {"stretch" | "center" | "flex-start" | "flex-end" | "space-between" | "space-around" | "initial" | "inherit"} props.alignContent align-content property
 * @param {number} props.order order property
 * @param {"string"} props.flex flex property
 * @param {"auto" | "stretch" | "center" | "flex-start" | "flex-end" | "baseline" | "initial" | "inherit"} props.alignSelf align-self property
 * @param {"row" | "row-reverse" | "column" | "column-reverse" | "initial" | "inherit"} props.flexDirection flex-direction property
 * @param {"auto" | "initial" | "inherit"} props.flexBasis flex-basis property
 * @param {string} props.flexFlow flex-flow property
 * @param {number | "initial" | "inherit"} props.flexGrow flex-grow property
 * @param {number | "initial" | "inherit"} props.flexShrink flex-shrink property
 * @param {"nowrap" | "wrap" | "wrap-reverse" | "initial" | "inherit"} props.flexWrap flex-wrap property
 * @param {string} props.width flex-width property
 * @param {string} props.height flex-height property
 */
export const FlexColumn = (props) => <Flex flexDirection="column" flexWrap="wrap" {...props} />;

const SpacerHelper = styled.div((props) => {
  let style = {};
  if (props.top) {
    style.paddingTop = props.top;
  }
  if (props.bottom) {
    style.paddingBottom = props.bottom;
  }
  if (props.left) {
    style.paddingLeft = props.left;
  }
  if (props.right) {
    style.paddingRight = props.right;
  }
  if (props.width) {
    style.width = props.width;
  }
  if (props.height) {
    style.height = props.height;
  }
  return style;
});

/**
 * Spacer div
 * @param {{top: string, bottom: string, left: string, right: string, width: string, height: string}} props Corresponding values for padding
 */
export const Spacer = (props) => <SpacerHelper {...props} />;

/**
 * Column for grid with 0 padding
 */
export const CompactCol = (props) => <Col {...props} style={{ padding: 0 }}></Col>;

export const SLGridLayout = (props) => <Grid {...props} style={{ maxWidth: "100%" }}></Grid>;

export const SLRowLayout = (props) => <Row {...props} style={{ maxWidth: "100%", paddingLeft: "10px" }}></Row>;

export const SLNotification = styled(Notification)`
  div > div {
    max-width: 100%;
  }
`;

const MarginWrapper = styled.div({
  margin: "0.2rem 0px",
});

export const ChangeList = styled.div`
  display: block;
  width: 100%;
`;

const ChangeListItemContainer = styled.div({
  "white-space": "normal",
});

export const ChangeListItem = (props) => (
  <ChangeListItemContainer {...props}>
    <Body size="small" {...props}></Body>
  </ChangeListItemContainer>
);

export const SmallText = (props) => (
  <MarginWrapper {...props}>
    <Body size="small" {...props}></Body>
  </MarginWrapper>
);

export const MediumText = (props) => (
  <MarginWrapper {...props}>
    <Body size="large" {...props}></Body>
  </MarginWrapper>
);

export const XLargeText = (props) => (
  <MarginWrapper {...props}>
    <Title primitive="p" size="large" {...props}></Title>
  </MarginWrapper>
);

export const LargeText = (props) => (
  <MarginWrapper {...props}>
    <Title primitive="p" size="small" {...props}></Title>
  </MarginWrapper>
);
export const UltraMediumText = styled.p`
  font-family: ${(props) => (props.eDSFont ? "Verizon-NHG-eDS" : "Verizon-NHG-eTX")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "bold")};
  color: #000000;
`;
export const StyledGridWrapper = styled.div`
  width: 100%;
  > div > div {
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: auto;
    background: ${({ theme }) => theme.background};
    /* > div > div {
      max-width: 100%;
      width: 100%;
      background: ${({ theme }) => theme.background};
    } */
  }
`;

export const SLButton = styled(Button)({
  margin: "10px 20px 10px 0px",
});

export const CustomSLButton = styled(SLButton)`
  & span {
    padding: 0.68rem 2rem;
    @media screen and (min-width: 700px) and (max-width: 720px) {
      padding: 0.68rem 0.8rem;
    }
    @media screen and (min-width: 720px) and (max-width: 750px) {
      padding: 0.68rem 0.895rem;
    }
    @media screen and (min-width: 751px) and (max-width: 850px) {
      padding: 0.68rem 1.2rem;
    }
  }
`;
