import React, { lazy, useEffect, useState } from "react";
import { Title } from "@vds/typography";
import { Loader } from "@vds/loaders";
import { generate } from "shortid";
import { Spacer, SLNotification } from "../../../components/core/Utility";
import ReportTable from "./ReportTable";
import { API_STATS_REPORT_COLUMNS } from "./ApiStatsReportConstants";
import { JOB_DETAILS_REPORT_COLUMNS } from "./JobDetailsReportConstants";
import { getReport } from "../../../services/scheduler/getReport";
import { Wrapper } from "./ReportTableStyle";
import styled from "@emotion/styled";

const StyledGridWrapper = styled.div`
  width: 90%;
  @media (max-width: 1024px) {
    width: 86%;
  }
`;

export default function SchedulerReport({ reportType }) {
  const [reporttype] = useState(reportType === "api_stats" ? "API Stats Report" : "Job Details Report");
  const [columnHeaders] = useState(reportType === "api_stats" ? API_STATS_REPORT_COLUMNS : JOB_DETAILS_REPORT_COLUMNS);
  const [rowData, setRowData] = useState([]);
  const [notification, setNotification] = useState(null);
  const [isLoading, setLoader] = useState(false);

  useEffect(() => {
    getReportData();
  }, []);

  const notify = (content, type) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} />);
    setTimeout(() => {
      setNotification(null);
    }, 5000);
  };

  const getReportData = async () => {
    setLoader(true);
    let response = await getReport(reportType);
    if (response.status === 200 && response?.data?.data) {
      let headers = columnHeaders.map((item) => item.field);
      let tableRowData = response.data.data.map((item) => {
        let data = {};
        for (const header of headers) {
          data = { ...data, [header]: header === "modifiedBy" ? item[header] || item.createdBy : item[header] };
        }
        return data;
      });
      setRowData(tableRowData);
    } else {
      notify("Unable to fetch data due to an unexpected error", "error");
    }
    setLoader(false);
  };

  return (
    <Wrapper>
      <Loader active={isLoading} fullscreen />
      <Spacer top="1rem" bottom="1rem">
        <div style={{ marginRight: "38rem" }}>{notification}</div>
        <Title primitive="p" size="large">
          {reporttype}
        </Title>
      </Spacer>
      <StyledGridWrapper>
        <ReportTable columnHeaders={columnHeaders} rowData={rowData} fileName={reporttype}></ReportTable>
      </StyledGridWrapper>
    </Wrapper>
  );
}

export const SchedulerReportMetadata = {
  name: "Scheduler Report",
  id: "scheduler_report",
  component: lazy(() => import("./SchedulerReport")),
  route: "/scheduler_report",
};
