import React, { useState, useContext, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Title } from "@vds/typography";
import { SLButton } from "../../components/core/Controls";
import { SmallText, FlexRow, Spacer } from "../../components/core/Utility/index";
import { SLNotification } from "../../../components/core/Utility";
import Icon from "@vds/icons";
import { Input } from "@vds/inputs/dist/cjs/components/Input";
import { addPage } from "../../../utility/navigation";
import { RescheduleJobMetadata } from "./ScheduleJob";
import { AccountDetailsContext } from "../../context/accountDetailsContext";
import { css } from "@emotion/react";
import moment from "moment";
import { generate } from "shortid";
import { TextLink } from "@vds/buttons";
import { handleStartDate } from "../../utility/sessionData";

const StyledDatePicker = css`
  .react-calendar {
    z-index: 5;
    position: fixed;
    margin-top: 0rem;
    @media screen and (min-width: 760px) {
      transform: translateY(-52%);
    }
  }
`;
export const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & mark {
    background-color: transparent !important;
  }
`;
export const CustomisedNotification = css`
  & span:last-child {
    max-width: fit-content;
  }
`;

const RescheduleModal = ({ jobDetails, setComponent }) => {
  const { accountId, accountDetails } = useContext(AccountDetailsContext);
  const [selectedDate, setSelectedDate] = useState();
  const [disabled, setDisabled] = useState(true);
  const [notification, setNotification] = useState();
  const [kbArticleLink] = useState("https://vzt-kb.vtitel.com/Reveal/Reveal_Support/Reveal_Scheduling/Customer_No_Show_Process");

  const confirmModal = async () => {
    let data = {
      id: jobDetails.jobId,
      status: jobDetails.status,
      startDate: new Date(selectedDate),
      appointmentLength: jobDetails.jobDuration,
      contactName: jobDetails.contactName,
      phone: jobDetails.contactPhone,
      email: jobDetails.contactEmail,
      accountId: accountId.accountId,
      address1: jobDetails.installationAddress.addressOne,
      address2: jobDetails.installationAddress.addressTwo ? jobDetails.installationAddress.addressTwo : null,
      city: jobDetails.installationAddress.city,
      stateCode: jobDetails.installationAddress.state,
      country: jobDetails.installationAddress.country,
      zipCode: jobDetails.installationAddress.zip,
      customerName: accountDetails.customerName,
      customerNumber: accountDetails.customerNumber,
      vendorId: jobDetails.vendorId,
      vendor: jobDetails.vendor,
      reschedule: true,
      vztSegment: accountDetails.vztSegment,
      modifiedAt: new Date(),
      modifiedBy: Object.keys(jobDetails).length ? accountDetails.sfdcUsername : null,
      createdAt: new Date(),
      createdBy: Object.keys(jobDetails).length ? null : accountDetails.sfdcUsername,
      isDeleted: false,
      serviceJobId: jobDetails.serviceJobId,
      scheduledDate: jobDetails.scheduledDate,
      scheduledTime: jobDetails.scheduledTime,
      timezone: jobDetails.timezone,
      latitude: jobDetails.latitude,
      longitude: jobDetails.longitude,
      scheduleStartTime: jobDetails?.scheduleStartTime || "",
      scheduleEndDate: jobDetails?.scheduleEndDate || "",
      scheduleEndTime: jobDetails?.scheduleEndTime || "",
      salesOrg: jobDetails?.salesOrg ?? "",
      jobDetails: [
        {
          cspSfdcId: jobDetails.cspSfdcId,
          primaryDevice: null,
          addOns: Object.keys(jobDetails).length ? "changed" : "updated",
          vehicleCount: jobDetails.vehicles,
          jobType: jobDetails.jobType,
          opportunityId: null,
          cspOwner: jobDetails.cspOwner,
          cspName: jobDetails.cspName,
          caseSfdcId: jobDetails.caseSfdcId,
          caseOwner: jobDetails.caseOwner,
          caseNumber: jobDetails.caseNumber,
          modifiedAt: new Date(),
          modifiedBy: Object.keys(jobDetails).length ? accountDetails.sfdcUsername : "",
          createdAt: new Date(),
          createdBy: Object.keys(jobDetails).length ? "" : accountDetails.sfdcUsername,
          isDeleted: false,
          workTickets: [
            {
              workTicket: jobDetails.workTicket,
            },
          ],
        },
      ],
    };
    setSelectedDate();
    setComponent(null);
    addPage(RescheduleJobMetadata.id, { jobData: data, jobDetails: jobDetails });
  };

  const handleDate = (e) => {
    setSelectedDate(moment(e).format("MM/DD/YYYY"));
    setDisabled(false);
  };

  useEffect(() => {
    let appointmentDate = new Date(jobDetails.scheduledDate + " " + jobDetails.scheduledTime).getTime();
    let currentDate = Date.now();
    if (appointmentDate > currentDate && appointmentDate - currentDate < 24 * 60 * 60 * 1000) {
      setNotification(
        <SLNotification
          type="warning"
          title={
            <>
              <span>
                You are rescheduling a job that is scheduled within the next 24 hours. No-show fees will apply. Please refer{" "}
                <TextLink
                  href={kbArticleLink}
                  size="small"
                  type="inline"
                  tabIndex="0"
                  role="link"
                  ariaLabel="kbArticleLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  KB Article
                </TextLink>
                .
              </span>
            </>
          }
          key={generate()}
          hideCloseButton={true}
          css={CustomisedNotification}
        />
      );
    }
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setComponent(null);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  return (
    <Modal
      role="dialog"
      ariaLabel="Reschedule Job Modal"
      opened={true}
      fullScreenDialog={false}
      disableOutsideClick={true}
      css={StyledModal}
      closeButton={
        <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
          <Title size="medium">Reschedule Job?</Title>
          <div onClick={() => setComponent(null)} css={{ marginRight: "1rem", marginTop: "0.3rem" }}>
            <Icon role="img" aria-label="close" name="close" size="medium" color="#000000" style={{ cursor: "pointer", outline: "none" }} />
          </div>
        </FlexRow>
      }
    >
      <ModalBody css={{ paddingLeft: "0", paddingRight: "0" }}>
        <Spacer top="1vw" />
        {notification}
        <Spacer top="1vw" />
        <SmallText size="small">
          You will be directed to the &quot;Reschedule Job&quot; page, where you can change your appointment scheduled for{" "}
          {moment(jobDetails.scheduledDate).format("MMM D, yyyy")} at {jobDetails.scheduledTime} {jobDetails.timezone}. You can reschedule with the
          same vendor only.
        </SmallText>
        <Spacer top="1vw" />
        <SmallText bold>If you want to reschedule with a different vendor, cancel this job, close your work ticket, and add a new job.</SmallText>
        <Spacer top="1rem" />
        <Input
          width="88%"
          type="calendar"
          readOnly={false}
          required={true}
          label="Select New Date"
          aria-label="Select New Date"
          onChange={(e) => handleDate(e)}
          value={selectedDate ? moment(selectedDate).format("MMM D, yyyy") : "Please select date"}
          css={StyledDatePicker}
          selectedDate={selectedDate ? new Date(selectedDate) : new Date(handleStartDate())}
          minDate={new Date(handleStartDate())}
          //   helperText="Please select date"
        />
      </ModalBody>
      {/* <ModalFooter> */}
      <FlexRow css={{ justifyContent: "flex-end" }}>
        <SLButton
          aria-label="Yes"
          role="button"
          size="large"
          disabled={disabled}
          aria-disabled={disabled}
          css={{ width: "10rem" }}
          onClick={confirmModal}
        >
          Yes
        </SLButton>
        <SLButton aria-label="No" role="button" size="large" secondary css={{ width: "10rem" }} onClick={() => setComponent(null)}>
          No
        </SLButton>
      </FlexRow>
      {/* </ModalFooter> */}
    </Modal>
  );
};
export default RescheduleModal;
