import Button from "@vds/buttons/dist/cjs/components/Button";
import styled from "styled-components";
import { ButtonGroup } from "@vds/buttons";
import { TextArea } from "@vds/inputs";
import { Subtitle, Body, Title } from "@vds/typography";
import { useContext, useState, useEffect } from "react";
import { updateVtuTable } from "../../services/checkin/updateServiceRequestNote";
import { CheckinContext } from "../../context/checkinContext";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { VIEW_SF_CASES_INFO_COL } from "../../constants/CheckInConstants";
import { notify } from "../../utility/getProperty";
import { FlexColumn, FlexRow, Spacer, SLNotification } from "../../components/core/Utility";
import { Line } from "@vds/lines";
import moment from "moment";
import { PrivilegeContext } from "../../context/privilegeContext";

const ViewWorkTicketInfoContainer = styled.div`
  height: 250px;
`;
const AllText = styled.div`
  margin-top: 20px;
  padding: 5px 0px 12px 5px;
  border: 1px solid ${({ theme }) => theme.border1};
  height: 300px;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  color: black;
  border-radius: 5px;
`;

const AllTextThird = styled.div`
  margin-top: 20px;
  padding: 5px 5px 12px 5px;
  border: 1px solid ${({ theme }) => theme.border1};
  height: 100px;
  width: 48%;
  display: flex;
  background-color: #87ceeb;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  color: black;
  border-radius: 5px;
`;
const AllTextSecond = styled.div`
  margin-top: 20px;
  padding: 5px 0px 12px 5px;
  border: 1px solid ${({ theme }) => theme.border1};
  height: 170px;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  color: black;
  border-radius: 5px;
`;

const TextSection = styled.div`
  // margin:10px 50px 100px 50px;
  margin-left: 20%;
  padding: 5px 10px 50px 1px;
  display: flex;
  justify-content: space-between;
  width: 1000px;
`;

const TextContainer = styled.div`
  width: 500px;
`;
const TextSectionSecond = styled.div`
  // margin:10px 50px 100px 50px;
  margin-left: 20%;
  //  padding: 5px 10px 50px 1px;
  display: flex;
  justify-content: space-between;
  width: 1000px;
`;
const NoteContainer = styled.div`
  height: auto;
  background-color: #a6d5fa;
  padding: 15px;
  width: auto;
  display: inline-block;
  margin: 15px 0;
`;
function ViewWorkTicketInfoLeft(props) {
  const { esn } = useContext(CheckinContext);
  const { esn: EsnValue } = esn;
  const [displayedNote, setDisplayedNote] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [notification, setNotification] = useState();
  const { userInfo } = useContext(PrivilegeContext);
  const { peripheralsStatus, setVerificationData, noteUpdated, setNotes, noteLog, setNoteLog, verficationStatus, fspComplete } = props;

  const handleNoteChange = (e) => {
    setDisplayedNote(e.target.value);
    setVerificationData({ notes: e.target.vale });
  };

  const submitNote = async () => {
    const response = await updateVtuTable({
      esn: EsnValue,
      note: displayedNote,
      action: "note",
    });

    if (response?.data?.statusCode === "200") {
      setNotes("Success");
      let note = `${moment().format("YYYY-MM-DD")} ${userInfo.email} ${displayedNote}`;
      setNoteLog([note, ...noteLog]);
      setVerificationData({ modifiedBy: userInfo.email });
      props.fetchUnitHistory();
    } else {
      setNotes("Failed to add Note");
    }
    setDisplayedNote("");
  };

  const submitPeripheralsStatus = async (key, value) => {
    const response = await updateVtuTable({
      esn: EsnValue,
      action: "togglePeripheralStatus",
      togglePeripheralType: key,
      togglePeripheralStatus: value,
    });

    if (response?.status === 200) {
      notify("Peripheral status updated successfully", "info", setNotification);
    } else {
      notify("Error occurred updating status", "info", setNotification);
    }
    props.fetchData();
  };
  const InfoEntry = ({ name, children, padding = "20px" }) => {
    return (
      <FlexRow padding="5px 0">
        <div style={{ paddingRight: padding }}>
          <Body size="large" bold>
            {" "}
            {name}
          </Body>
        </div>
        <Body size="large">{children}</Body>
      </FlexRow>
    );
  };
  const ColumnData = ({ values, bold = false, padding = "0px" }) => {
    return (
      <>
        <FlexColumn paddingRight={padding}>
          {values.map((data, index) => {
            return (
              <>
                <Body key={index} bold={bold} size="large">
                  {data || <span>&nbsp;</span>}
                </Body>
                <div style={{ height: "4px" }} />
              </>
            );
          })}
        </FlexColumn>
      </>
    );
  };
  const handleStatusUpdate = async (statusType, action) => {
    let inputObj = {
      esn: EsnValue,
      action: "multiple",
    };
    if (statusType === "verfStatus") {
      if (action === "Pass") {
        inputObj["checkinValidated"] = true;
        inputObj["checkinComplete"] = true;
      } else {
        inputObj["checkinValidated"] = false;
        inputObj["checkinValidated"] = false;
      }
    } else {
      inputObj["fspValidated"] = action === "Pass" ? true : false;
    }
    const response = await updateVtuTable(inputObj);
    if (response.error) {
      throw new Error(response.error);
    } else {
      if (statusType === "verfStatus") {
        setVerificationData({
          checkinValidated: inputObj.checkinValidated,
          checkinComplete: inputObj.checkinComplete,
          verficationStatus: action,
        });
      } else {
        setVerificationData({
          fspValidated: inputObj.fspValidated,
          fspComplete: action,
        });
      }
    }
  };

  return (
    <>
      {notification}
      {errorMessage !== "" ? (
        <SLNotification
          type="error"
          title
          onCloseButtonClick={() => setErrorMessage("")}
          subtitle={errorMessage}
          fullBleed={false}
          inlineTreatment={false}
        />
      ) : (
        <></>
      )}

      {peripheralsStatus && (
        <>
          <Title size="small" bold>
            Peripherals Status
          </Title>
          <Spacer height="10x" />
          <FlexRow justifyContent="space-between">
            {Object.keys(peripheralsStatus).map((item) => {
              return (
                !!peripheralsStatus[item] && (
                  <FlexColumn width="600px" justifyContent="center" height="117px" alignItems="center">
                    <>
                      <InfoEntry name={`${item}:`} padding="5px">
                        {peripheralsStatus[item]}
                      </InfoEntry>
                      <Spacer height="20px"></Spacer>
                      <div>
                        <ButtonGroup
                          childWidth={"150%"}
                          viewport={"desktop"}
                          rowQuantity={{ desktop: 2 }}
                          data={[
                            {
                              children: "Pass",
                              size: "small",
                              type: "secondary",
                              width: "100px",
                              onClick: () => submitPeripheralsStatus(item, "Pass"),
                            },
                            {
                              children: "Incomplete",
                              size: "small",
                              type: "secondary",
                              width: "100px",
                              onClick: () => submitPeripheralsStatus(item, "Incomplete"),
                            },
                            {
                              children: "Fail",
                              size: "small",
                              type: "secondary",
                              width: "100px",
                              onClick: () => submitPeripheralsStatus(item, "Fail"),
                            },
                          ]}
                        />
                      </div>
                    </>
                  </FlexColumn>
                )
              );
            })}
          </FlexRow>
          <Spacer top="10px" bottom="10px">
            <Line type="light"></Line>
          </Spacer>
        </>
      )}

      <Title size="small" bold>
        {" "}
        Verification Data
      </Title>
      <Spacer height="10px" />
      <FlexRow justifyContent="space-between">
        <FlexColumn width="550px" justifyContent="center" height="100px" alignItems="center">
          <InfoEntry name="Verification Status:" padding="5px">
            {verficationStatus}
          </InfoEntry>
          <Spacer height="10px" />
          <div>
            <ButtonGroup
              childWidth={"150%"}
              viewport={"desktop"}
              rowQuantity={{ desktop: 2 }}
              data={[
                {
                  children: "Pass",
                  size: "small",
                  type: "secondary",
                  width: "100px",
                  onClick: () => handleStatusUpdate("verfStatus", "Pass"),
                },
                {
                  children: "Incomplete",
                  size: "small",
                  type: "secondary",
                  width: "100px",
                  onClick: () => handleStatusUpdate("verfStatus", "Incomplete"),
                },
              ]}
            />
          </div>
        </FlexColumn>
        <FlexColumn width="550px" justifyContent="center" height="100px" alignItems="center">
          <InfoEntry name="FSP Complete: " padding="5px">
            {fspComplete}
          </InfoEntry>
          <Spacer height="20px" />
          <div>
            <ButtonGroup
              childWidth={"150%"}
              viewport={"desktop"}
              rowQuantity={{ desktop: 2 }}
              data={[
                {
                  children: "Pass",
                  size: "small",
                  type: "secondary",
                  width: "100px",
                  onClick: () => handleStatusUpdate("fsp", "Pass"),
                },
                {
                  children: "Fail",
                  size: "small",
                  type: "secondary",
                  width: "100px",
                  onClick: () => handleStatusUpdate("fsp", "Fail"),
                },
              ]}
            />
          </div>
        </FlexColumn>
      </FlexRow>
      <Spacer top="10px" bottom="10px">
        <Line type="light"></Line>
        {noteUpdated !== "" && (
          <SLNotification
            type={noteUpdated === "Success" ? "info" : "error"}
            title
            onCloseButtonClick={() => setNotes("")}
            subtitle={noteUpdated}
            fullBleed={false}
            inlineTreatment={false}
          />
        )}
      </Spacer>
      <Title size="small" bold>
        Notes
      </Title>
      <div style={{ padding: "10px" }}>
        <TextArea
          readOnly={false}
          required={false}
          disabled={false}
          error={false}
          value={displayedNote}
          helperText=""
          helperTextPlacement="bottom"
          onChange={handleNoteChange}
          maxLength={2000}
          width="1000px"
        />
      </div>
      <FlexRow padding="10px 0">
        <Button size="large" onClick={submitNote}>
          Submit Note
        </Button>
      </FlexRow>
      {noteLog?.length > 0 && (
        <NoteContainer>
          {noteLog?.length > 0 &&
            noteLog?.map((note, index) => {
              return <div key={index}>{note}</div>;
            })}
        </NoteContainer>
      )}
      <Spacer top="10px" bottom="10px">
        <Line type="light"></Line>
      </Spacer>
      <Title size="small" bold={true}>
        FastStart Data
      </Title>
      <FlexRow justifyContent="space-between" paddingTop="10px">
        <FlexRow width="500px">
          <ColumnData
            bold
            values={["Group:", "Location:", "VehicleNumber:", "DriverNumber:", "DriverFirstName:", "DriverLastName:"]}
            padding="10px"
          />
          <ColumnData
            values={[
              props.data.group,
              props.data.location,
              props.data.vehicleNumber,
              props.data.driverNumber,
              props.data.driverFirstName,
              props.data.driverLastName,
            ]}
          />
        </FlexRow>
        <FlexRow width="500px">
          <ColumnData bold values={["DriverEmail:", "DriverCellPHone:", "Garmin:", "PTO:", "LogBook:"]} />
          <ColumnData values={[props.data.driverEmail, props.data.driverCellPhone, props.data.garmin, props.data.pto, props.data.logBook]} />
        </FlexRow>
      </FlexRow>
      <Spacer top="10px" bottom="10px">
        <Line type="light"></Line>
      </Spacer>
      <Title size="small" bold>
        {" "}
        Last Modified
      </Title>
      <Spacer height="10px" />
      <InfoEntry name="Email:">{props.lastModifiedBy}</InfoEntry>
      <Spacer top="10px" bottom="10px">
        <Line type="light"></Line>
      </Spacer>
    </>
  );
}

export default ViewWorkTicketInfoLeft;
