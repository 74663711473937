import { COLUMN_WIDTHS } from "../../../constants/ApplicationConstants";

export const controlUnitColumns = [
  {
    key: "ESN",
    value: "esn",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: false,
  },
  {
    key: "DRID",
    value: "drid",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: false,
  },
  {
    key: "ICCID",
    value: "iccid",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: false,
  },
  {
    key: "IMEI",
    value: "imei",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: false,
  },
  {
    key: "Box Type",
    value: "boxType",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: false,
  },
  {
    key: "Reveal VTU Id",
    value: "vehicleTrackingId",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: false,
  },
  {
    key: "Product ID",
    value: "sku",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: false,
  },
  {
    key: "TYPE",
    value:"cameraProductType",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  }
];

export const standAloneCamerasColumns = [
  {
    key: "ESN",
    value: "esn",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "STATUS",
    value: "status",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "STATE",
    value: "state",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "TYPE",
    value:"cameraProductType",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isEditEnabled: true,
  },
  {
    key: "CHANNEL NUMBER",
    value: "channelNumber",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isEditEnabled: true
  }
];

export const selectedCamerasColumns = [
  {
    key: "ESN",
    value: "esn",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "TYPE",
    value:"assignedFunction",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isEditEnabled: true,
  },
  {
    key: "CHANNEL NUMBER",
    value: "channelNumber",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isEditEnabled: true
  }
];

export const workTicketColumns = [
  {
    key: "Work Ticket",
    value: "workTicketNumber",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Order Type",
    value: "orderDetailType",
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Tech Ref ID",
    value: "techRefId",
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Line Item",
    value: "lineItemDescription",
    width: COLUMN_WIDTHS.large,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Vendor",
    value: "vendorID",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Installation Address",
    value: "installationAddress",
    width: COLUMN_WIDTHS.large,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Ticket Type",
    value: "workTicketType",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "CRM Reference ID",
    value: "crmReferenceId",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Status",
    value: "status",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
];

export const lineItemColumns = [
  {
    key: "Line Item",
    value: "lineItem",
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Number",
    value: "number",
    width: COLUMN_WIDTHS.small,
  },
];

export const cameraHistoryColumns = [
  {
    key: "ESN",
    value: "esn",
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "DRID",
    value: "drid",
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "ICCID",
    value: "iccid",
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "IMEI",
    value: "imei",
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Box Type",
    value: "boxType",
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Reveal VTU Id",
    value: "vehicleTrackingId",
    width: COLUMN_WIDTHS.small,
  },
];

export const aiDashCamSkus =[
  "KP2-VZA-DFC-128",
  "KP2-VZA-DFC-64",
  "KP2-VZ-DFC-128",
  "KP2-VZ-DFC-64",
  "KP2-VZE-DFC-128",
  "KP2-VZE-DFC-64"
]